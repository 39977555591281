export default Object.freeze({
    CardSchemeFee: 'CardSchemeFee',
    MerchantBankAccount: 'MerchantBankAccount',
    Merchant: 'Merchant',
    Subscription: 'Subscription',
    Adjustment: 'Adjustment',
    BankChannel: 'BankChannel',
    Payment: 'Payment',
    Transaction: 'Transaction',
    PartnerBankAccount: 'PartnerBankAccount',
    PartnerSubscription: 'PartnerSubscription',
    CardSchemeCommission: 'CardSchemeCommission',
    Partner: 'Partner',
    Terminal: 'Terminal',
});
