import moment from 'moment-timezone';
import months from '../Enums/months';

export function stringifyDate(time, format = 'YYYY-MM-DD') {
    const year = time.getFullYear();
    const month = time.getMonth() + 1;
    const date = time.getDate();
    const monthName = months[time.getMonth()];

    const map = {
        YYYY: year,
        MMM: monthName,
        MM: ('0' + month).slice(-2),
        M: month,
        DD: ('0' + date).slice(-2),
        D: date,
    };
    return format.replace(/Y+|M+|D+/g, function (str) {
        return map[str];
    });
}

export function formatDate(value, format = 'YYYY-MM-DD', local = false) {
    if (!value) {
        return '—';
    }

    if (local) {
        return moment(value).format(format);
    }

    const UTCDate = moment.utc(value).toDate();
    return moment(UTCDate).local().format(format);
}

/**
 * Calculates the time remaining until a given ISO date string
 * @param {string} isoString - The ISO date string to calculate time until
 * @param {string} [unit='minutes'] - The unit to return the difference in ('seconds', 'minutes', 'hours')
 * @returns {number|null} - Number of units until the given date or null if input is invalid
 */
export function timeUntil(isoString, unit = 'minutes') {
    if (!isoString) {
        return null;
    }

    const validUnits = ['seconds', 'minutes', 'hours'];
    if (!validUnits.includes(unit)) {
        unit = 'minutes';
    }

    const future = moment(isoString);
    const now = moment();

    if (future.isBefore(now)) {
        return 0;
    }

    return future.diff(now, unit);
}
