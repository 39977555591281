let http = undefined;
const setHttp = (instance) => {
    http = instance;
};

/**
 * Composable for managing HTTP client instance
 * @returns {{
 *   setHttp: (instance: import('axios').AxiosInstance) => void,
 *   http: () => import('axios').AxiosInstance
 * }} Object containing setHttp function and http instance getter
 * @description Provides functions to set and get the configured HTTP client instance
 * @example
 * ```js
 * const { http, setHttp } = useHttp();
 *
 * // Set the HTTP instance (usually done in a base component)
 * setHttp(axiosInstance);
 *
 * // Use the HTTP instance
 * http().get('/api/users');
 * ```
 */
export const useHttp = () => {
    return {
        setHttp,
        http,
    };
};
