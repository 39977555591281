import { getEnumObject } from '../utilities.js';

export const partnerTypes = {
    BRAND: 1,
    FRANCHISE: 2,
    MULTIPLE_CRS: 3,
    REFERRALS: 4,
    ONLINE_AGGREGATOR: 5,
};

export default getEnumObject(partnerTypes);
