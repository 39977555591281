import routeStatus from '@/common/Enums/routeStatus';
import featureFlagsEnum from './Enums/featureFlagsEnum';

export default [
    {
        name: 'dashboard',
        route: '/dashboard',
        icon: 'dashboard_icon',
        activeSidebarItemId: 'dashboard',
        meta: {},
    },
    {
        name: 'customer_insights',
        icon: 'donut_small',
        activeSidebarItemId: 'customer_insights',
        meta: {
            status: routeStatus.BETA,
        },
        children: [
            {
                name: 'customer_insights_dashboard',
                route: '/customer-insights',
                activeSidebarItemId: 'customer_insights_dashboard',
                meta: {
                    featureFlag: featureFlagsEnum.CUSTOMER_INSIGHTS,
                },
            },
            {
                name: 'customer_segments',
                route: '/customer-segments',
                activeSidebarItemId: 'customer_segments',
                meta: {
                    featureFlag: featureFlagsEnum.CUSTOMER_INSIGHTS,
                },
            },
        ],
    },
    {
        name: 'payments',
        route: '/payments',
        icon: 'payments',
        activeSidebarItemId: 'payments',
        meta: {
            requireApproval: true,
        },
    },
    {
        name: 'transactions',
        route: '/transactions',
        icon: 'bid_landscape',
        activeSidebarItemId: 'transactions',
        meta: {
            requireApproval: true,
        },
    },
    {
        name: 'reports',
        icon: 'show_chart',
        activeSidebarItemId: 'reports',
        meta: {
            requireApproval: true,
        },
        children: [
            {
                name: 'bank_reconciliation_report',
                route: '/bank_reconciliation_report',
                activeSidebarItemId: 'bank_reconciliation_report',
                meta: {
                    status: routeStatus.BETA,
                    requireApproval: true,
                    featureFlag: featureFlagsEnum.BANK_RECONCILIATION_REPORT,
                },
            },
        ],
    },
    {
        name: 'statements',
        icon: 'article',
        activeSidebarItemId: 'statements',
        meta: {
            requireApproval: true,
        },
        children: [
            {
                name: 'vat_statements',
                route: '/vat-statements',
                activeSidebarItemId: 'vat_statements',
                meta: {
                    requireApproval: true,
                },
            },
            {
                name: 'vat_invoices',
                route: '/vat-invoices',
                activeSidebarItemId: 'vat_invoices',
                meta: {
                    requireApproval: true,
                },
            },
            {
                name: 'account_statement',
                route: '/account-statement',
                activeSidebarItemId: 'account_statement',
                meta: {
                    requireApproval: true,
                    status: routeStatus.NEW,
                },
            },
        ],
    },
    {
        name: 'deductions',
        icon: 'event_busy',
        activeSidebarItemId: 'deductions',
        meta: {
            requireApproval: true,
        },
        children: [
            {
                name: 'subscriptions',
                route: '/subscriptions',
                activeSidebarItemId: 'subscriptions',
                meta: {
                    requireApproval: true,
                },
            },
            {
                name: 'loans',
                route: '/loans',
                activeSidebarItemId: 'loans',
                meta: {
                    requireApproval: true,
                },
            },
        ],
    },
    {
        name: 'manage',
        icon: 'settings_icon',
        activeSidebarItemId: 'manage',
        meta: {
            requireApproval: true,
        },
        children: [
            // {
            //     name: 'terminal_recon',
            //     route: '/terminal-recon',
            //     activeSidebarItemId: 'terminal_recon',
            //     meta: {
            //         requireApproval: true,
            //     },
            // },
            {
                name: 'bank_accounts',
                route: '/bank-accounts',
                activeSidebarItemId: 'bank_accounts',
                meta: {
                    requireApproval: true,
                    status: routeStatus.NEW,
                },
            },
            {
                name: 'branches',
                route: '/branches',
                activeSidebarItemId: 'branches',
                meta: {
                    requireApproval: true,
                },
            },
            {
                name: 'terminals',
                route: '/terminals',
                activeSidebarItemId: 'terminals',
                meta: {
                    requireApproval: true,
                },
            },
            {
                name: 'users',
                route: '/users',
                activeSidebarItemId: 'users',
                meta: {
                    requireApproval: true,
                    isOwner: true,
                },
            },
        ],
    },
];
