const CustomerInsights = () =>
    import(
        '../Screens/CustomerInsights/Screens/Dashboard/CustomerInsights.vue'
    );

const CustomerSegments = () =>
    import(
        '../Screens/CustomerInsights/Screens/CustomerSegments/CustomerSegments.vue'
    );

const CustomerSegmentView = () =>
    import(
        '../Screens/CustomerInsights/Screens/CustomerSegmentForm/CustomerSegmentView.vue'
    );
export default [
    {
        path: '/customer-insights',
        name: 'customer_insights_dashboard',
        component: CustomerInsights,
        meta: {
            requireAuth: true,
            requireApproval: true,
            mixpanelPageName: 'Customer Insights',
            activeSidebarItemId: 'customer_insights_dashboard',
            pageTitle: 'customer_insights',
        },
    },
    {
        path: '/customer-segments',
        meta: {
            requireAuth: true,
            requireApproval: true,
            mixpanelPageName: 'Customer Segments',
            activeSidebarItemId: 'customer_segments',
            pageTitle: 'customer_segments',
        },
        children: [
            {
                path: '',
                name: 'customer_segments',
                component: CustomerSegments,
                meta: {
                    requireApproval: true,
                    mixpanelPageName: 'Customer Segments',
                    activeSidebarItemId: 'customer_segments',
                    pageTitle: 'customer_segments',
                },
            },
            {
                path: 'create',
                name: 'customer_segments_create',
                component: CustomerSegmentView,
                meta: {
                    requireApproval: true,
                    mixpanelPageName: 'Create Customer Segment',
                    pageTitle: 'customer_segments_create',
                },
            },
            {
                path: ':id',
                name: 'customer_segments_edit',
                component: CustomerSegmentView,
                props: true,
                meta: {
                    mixpanelPageName: 'Edit Customer Segments',
                    pageTitle: 'customer_segments_edit',
                },
            },
        ],
    },
];
