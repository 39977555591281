export default {
    page_title: {
        mobile_registration: 'تسجيل رقم الهاتف المحمول',
    },
    mobile_registration: {
        subtitle:
            'يرجى تزويدنا برقم هاتفك لإرسال رمز مرور مؤقت (OTP) لتسجيل الدخول',
        send_otp: 'إرسل رمز التحقق',
        fields: {
            mobile: 'الرجاء إدخال رقم هاتفك',
        },
        back: 'العودة لتسجيل رقم الهاتف',
        enter_otp: 'لقد قمنا بإرسال رمز التحقق (OTP) إلى رقم هاتفك المسجل {0}.',
        lost_access: 'لا أستطيع الوصول إلى هذا الرقم.',
        reset_alert: {
            title: 'إعادة تعيين رقم الهاتف المحمول',
            message:
                'بالمتابعة، سيتم إرسال رمز التحقق إلى عنوان بريدك الإلكتروني المسجل وسيتم إعادة تعيين رقم هاتفك المحمول. هل أنت متأكد أنك تريد المتابعة؟',
            confirm: 'متابعة',
            cancel: 'إلغاء',
        },
    },
};
