const Dashboard = () => import('../Screens/Dashboard/Dashboard.vue');
const NotFound = () => import('../Screens/NotFound.vue');
const Unauthorized = () => import('../Screens/Unauthorized.vue');
import branchesRouts from './branches.js';
import paymentsRoutes from './payments.js';
import myAccountRoutes from './myAccount.js';
import terminalsRoutes from './terminals.js';
import transactionsRoutes from './transactions.js';
import usersRoutes from './users.js';
import vatInvoicesRoutes from './vatInvoices.js';
import vatStatementsRoutes from './vatStatements.js';
import forgotPasswordRoutes from './forgetPassword.js';
import loginRoutes from './login.js';
import onboarding from './onboarding.js';
import resetPasswordRoutes from './resetPassword.js';
import verifyRoutes from './verify.js';
import otpRoutes from './otpVerification.js';
import nearpayRegistrationRoutes from './nearpayRegistration.js';
import TerminalReconRoutes from './terminalRecon.js';
import AccountStatement from './accountStatement.js';
import loans from './loans.js';
import subscriptions from './subscriptions.js';
import deductions from './deductions.js';
import bankAccounts from './bankAccounts.js';
import customerInsights from './customerInsights.js';
import bankReconciliationReport from './bankReconciliationReport.js';
import mobileRegistration from './mobileRegistration.js';

export default [
    {
        path: '/',
        redirect: 'dashboard',
    },
    ...loginRoutes,
    ...forgotPasswordRoutes,
    ...resetPasswordRoutes,
    ...transactionsRoutes,
    ...paymentsRoutes,
    ...terminalsRoutes,
    ...branchesRouts,
    ...vatStatementsRoutes,
    ...vatInvoicesRoutes,
    ...myAccountRoutes,
    ...verifyRoutes,

    ...onboarding,
    ...nearpayRegistrationRoutes,
    ...usersRoutes,
    ...otpRoutes,
    ...TerminalReconRoutes,
    ...AccountStatement,
    ...loans,
    ...subscriptions,
    ...deductions,
    ...bankAccounts,
    ...customerInsights,
    ...bankReconciliationReport,
    ...mobileRegistration,
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            requireAuth: true,
            mixpanelPageName: 'Dashboard',
            activeSidebarItemId: 'dashboard',
            pageTitle: 'dashboard',
        },
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('../Screens/Auth/Logout/logout.vue'),
        meta: {
            mixpanelPageName: 'Log out',
            pageTitle: 'logout',
        },
    },
    {
        path: '/impersonation',
        name: 'impersonation',
        component: () =>
            import('../Screens/Auth/Impersonation/Impersonation.vue'),
        meta: {
            mixpanelPageName: 'Impersonation',
            pageTitle: 'impersonation',
        },
    },
    {
        path: '/404',
        name: '404',
        component: NotFound,
        meta: {
            mixpanelPageName: 'Not Found',
            pageTitle: 'not_found',
        },
    },
    {
        path: '/unauthorized',
        name: 'unauthorized',
        component: Unauthorized,
        meta: {
            mixpanelPageName: 'Unauthorized',
            pageTitle: 'unauthorized',
        },
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/404',
    },
];
