export default {
    customer_insights_menu: 'Customer Insights',
    customer_insights_dashboard_menu: 'Dashboard',
    customer_segments_menu: 'Customer Segments',
    page_title: {
        customer_insights: 'Customer Insights',
        customer_segments: 'Customer Segments',
        customer_segments_create: 'Create Customer Segment',
        customer_segments_edit: 'Edit Customer Segment',
    },
    customer_insights: {
        help: 'This dashboard currently captures data from payments made on Customer Display Screens. Please be aware that, due to the use of unique card identifiers, the same customer may appear as separate entries if they pay with multiple cards or use different payment methods (such as Apple Pay). We’re actively working on solutions to unify these entries and provide a more comprehensive view of your customers. Additionally, we plan to expand data capture to other payment terminals in the future for broader insights.',
        info: 'Gain deeper insights into your Customer Display Screen transactions. Track customer growth, measure loyalty, and optimize marketing strategies.',
        timeline: {
            week: 'Last 7 Days',
            month: 'Last 30 Days',
            quarter: 'Last 3 Months',
            previous: {
                week: 'previous 7 days',
                month: 'previous 30 days',
                quarter: 'previous 3 months',
            },
        },
        something_went_wrong: 'Something went wrong!',
        unexpected_error: 'Oops! Looks like an unexpected error occurred.',
        not_able_to_fetch: 'We are unable to fetch the information.',
        footfall: {
            title: 'Daily Footfall',
            info: 'This chart visualizes daily trends in customer activity, showing new and repeated customer patterns. Use this to identify the impact of specific days or promotions.',
        },
        trends: {
            increase: 'increase vs. {timeline}',
            decrease: 'decrease vs. {timeline}',
            steady: 'Steady vs. {timeline}',
        },
        stats: {
            overview: {
                title: 'Overview',
                count: 'Total Transaction Count',
                value: 'Total Transaction Value',
                customers: 'Total Customers',
            },
            breakdown: {
                title: 'Detailed Breakdown',
                segment: 'Segment',
                customers: 'Customers',
                transactions_count: 'Transactions Count',
                transactions_value: 'Transactions Value',
                transactions_avg_value: 'Average Transaction Value',
                uncategorized_info:
                    'This data belongs to a segment that has been deleted.',
            },
        },
    },
    customer_segments: {
        new: 'Create Segment',
        create: 'Create Segment',
        level: 'Level',
        timeframe: 'Timeframe',
        visits: 'Visits',
        spending: 'Spending',
        branches: 'Branches',
        name: 'Name',
        visits_frequency: 'Visitation Frequency',
        week: 'Week | Weeks',
        month: 'Month | Months',
        day: 'Day | Days',
        different_branches: 'Different Branch | Different Branches ',
        per: 'Per',
        level_tooltip:
            'This determines which segment a customer will be assigned to if they meet the criteria for multiple segments. A higher level takes priority.',
        cancel: 'Cancel',
        save: 'Save',
        unauthorized: {
            info: 'You have reached the maximum number of segments.',
        },
        success: {
            title: 'Segment Sorted',
            message:
                'Your segments have been sorted successfully. The changes will be applied within the next 24 hours.',
        },
        error: {
            title: 'Something went wrong',
            message: 'Please refresh the page and try again',
        },
    },
    segment_form: {
        title_placeholder: 'Customer',
        visits: 'Visits',
        forecast: {
            title: 'Forecast (Estimate)',
            tooltip:
                'This is an estimated number and may not reflect the actual count. It can be used as a guide but not as a definitive source of truth.',
            customers: 'Customer | Customers',
        },
        fields: {
            title: 'Name',
            color: 'Color',
            per: 'Per',
            timeframe: 'Timeframe',
            average_visits: 'Average Visits',
            average_spending: 'Average at least',
            unique_visited_branches: 'Visited at least',
            visits_frequency: 'Visitation Frequency',
            spending: 'Spending',
            branches: 'Branches',
        },
        help: {
            timeframe:
                'Select the time period over which customer behavior is analyzed for this segment (e.g., the last 3 months).',
            average_visits:
                'Set the minimum average number of visits customers must make during the selected timeframe to be part of this segment.',
            average_spending:
                'Set the minimum average amount customers must spend during the selected timeframe to qualify for this segment.',
            unique_visited_branches:
                'Set the minimum number of different branches customers must visit during the timeframe to be included in this segment.',
        },
        month: 'Month',
        week: 'Week',
        timeframe: 'Timeframe',
        different_branches: 'Different Branches',
        validation: {
            required: 'This field is required',
            numeric: 'This field must be a number',
            integer: 'This field must be an integer',
            name_max_length: 'Name must be at most 16 characters',
            min_visits: 'The average visits must be at least 1',
            max_visits: 'The average visits must be at most 500',
            min_spending: 'The average spending must be at least 1',
            max_spending: 'The average spending must be at most 10000',
            min_branches: 'The different branches must be at least 1',
            max_branches: 'The different branches must be at most 100',
            toggles_error: 'Please at least enable one of the toggles below',
            forecast_min:
                'Your segment is a bit too specific. Please consider expanding your criteria.',
            forecast_max:
                'Your segment is too broad. Please consider narrowing your criteria.',
        },
        create: {
            title: 'Create {0} Segment',
            success: {
                title: 'Segment Created',
                message:
                    'Segment created successfully. The changes will be applied within the next 24 hours.',
            },
            error: {
                title: 'Something went wrong',
                message: 'Please refresh the page and try again',
            },
        },

        edit: {
            title: 'Edit {0} Segment',
            success: {
                title: 'Segment Updated',
                message:
                    'Segment updated successfully. The changes will be applied within the next 24 hours.',
            },
            error: {
                title: 'Something went wrong',
                message: 'Please refresh the page and try again',
            },
        },

        delete: {
            title: 'Delete',
            confirm: {
                title: 'Are you sure you want to delete this segment?',
                message:
                    'This action is irreversible, and the segment will be permanently removed.',
                proceed: 'I understand, delete',
            },
            success: {
                title: 'Segment Deleted',
                message:
                    'Segment deleted successfully. The changes will be applied within the next 24 hours.',
            },
            error: {
                title: 'Something went wrong',
                message: 'Please refresh the page and try again',
            },
        },
    },
};
