const MobileRegistrationView = () =>
    import('../Screens/Auth/MobileRegistration/MobileRegistrationView.vue');

export default [
    {
        path: '/mobile-registration',
        name: 'mobile-registration',
        component: MobileRegistrationView,
        meta: {
            requireAuth: true,
            mixpanelPageName: 'Mobile Registration',
            transition: 'slide-down',
            pageTitle: 'mobile_registration',
        },
    },
];
