import { merge } from 'lodash';
import arCommon from '@/common/I18n/ar.js';
import customerInsights from './CustomerInsights/ar.js';
import notFound from './NotFound/ar.js';
import unauthorized from './Unauthorized/ar.js';
import phoneVerfication from './MobileRegistration/ar.js';

const translations = {
    ...arCommon,
    dashboard: {
        title: 'لوحة البيانات',
        tabs: {
            day: 'يوم',
            week: 'اسبوع',
            month: 'شهر',
        },
    },
    other_lang: 'en',
    other_lang_long: 'English',
    terminals: 'الأجهزة',
    payments: 'الدفعات',
    home: 'الرئيسة',
    account: 'بيانات الحساب',
    contact_us: 'تواصل معنا',
    logout: 'تسجيل الخروج',
    total_transaction_amount: 'قيمة العمليات الإجمالية',
    total_received_amount: 'قيمة الدفعات',
    terminal_id8: 'رقم الجهاز ٨',
    total_terminals: 'عدد الأجهزة الإجمالي',
    active_terminals: 'عدد الأجهزة المفعلة',
    pending_activation: 'في انتظار التفعيل',
    enabled_card_schemes: 'طرق الدفع المفعلة',
    channel: 'نوع الجهاز',
    transaction_amount: 'قيمة العمليات',
    transaction_number: 'رقم العملية',
    total_amount: 'القيمة الإجمالية',
    settled_amount: 'القيمة المسواة',
    transaction_count: 'عدد العمليات',
    date: 'التاريخ',
    sar: 'ر.س',
    month: 'الشهر',
    month_range: 'نطاق الشهور',
    total_fees: 'إجمالي الرسوم',
    details: 'التفاصيل',
    monthly: 'شهريًا',
    weekly: 'أسبوعيًا',
    daily: 'يوميًّا',
    ticket_type: 'نوع التذكرة',
    terminal_id: 'رقم الجهاز',
    name: 'الاسم',
    email: 'البريد الإلكتروني',
    mobile_number: 'رقم الجوال',
    message: 'الرسالة',
    message_placeholder: 'فضلًا أدخل تفاصيل المشكلة أو الاستفسار..',
    submit: 'إرسال',
    done: 'إنهاء',
    back: 'رجوع',
    log_in: 'تسجيل الدخول',
    password: 'أدخل كلمة المرور',
    login_to_your_account: 'تسجيل الدخول إلى {0}',
    confirm_password: 'تأكيد كلمة المرور',
    password_mismatch: 'كلمة المرور وتأكيد كلمة المرور غير متطابقين',
    invalid_password:
        'يجب أن تتكون كلمة المرور من 10 أحرف على الأقل مع حرف كبير وحرف صغير ورقم و رمز على الأقل',
    invalid_credentials: 'بيانات الدخول غير صحيحة',
    please_wait: 'فضلًا انتظر قليلًا...',
    login_unknown_error:
        'ثم خطأ ما. فضلًا تأكد من اتصالك بالشبكة وحاول مرة أخرى',
    terminal_issue: 'مشكلة في الجهاز',
    app_issue: 'مشكلة في التطبيق',
    subscription_issue: 'الأشتراكات',
    inquiry: 'استفسار',
    complaint: 'شكوى',
    suggestion: 'اقتراح',
    other: 'أخرى',
    cancel: 'إلغاء',
    ok: 'موافق',
    required_ticket_type: 'نوع التذكرة مطلوب',
    required_terminal_id: 'رقم الجهاز مطلوب',
    invalid_terminal_id: 'رقم الجهاز يجب أن يتكون من 8 أرقام',
    required_name: 'الاسم مطلوب',
    required_email: 'البريد الإلكتروني مطلوب',
    required_owner_email: 'البريد الإلكتروني للمالك مطلوب',
    required_mobile: 'رقم الجوال مطلوب',
    required_message: 'الرسالة مطلوبة',
    invalid_email: 'فضلًا أدخل بريد إلكتروني صحيح',
    invalid_mobile: 'فضلًا أدخل رقم جوال صحيح',
    thank_you_for_contacting: 'شكرًا لتواصلكم معنا. سنقوم بالرد عليكم قريبًا.',
    thank_you_for_registration: 'شكرًا لإتمامكم عملية التسجيل!',
    could_not_submit_request: 'لم نتمكن من إرسال الطلب.',
    sn: 'ر.ت.',
    total_transactions: 'إجمالي العمليات',
    week: 'الأسبوع',
    merchant_name: 'اسم التاجر',
    merchant_name_arabic: 'اسم التاجر بالعربية',
    city: 'المدينة',
    branch_city: 'مدينة الفرع',
    address: 'العنوان',
    bank_name: 'اسم البنك',
    beneficiary_name: 'اسم الحساب',
    bank_beneficiary_name: 'اسم المستفيد',
    iban: 'رقم الأيبان IBAN',
    mada_fee: 'رسوم بطاقة مدى',
    credit_card_fee: 'رسوم البطاقات الإئتمانية',
    contact_number: 'رقم التواصل',
    search_terminal_placeholder: 'ابحث عن طريق رقم الجهاز..',
    transactions: 'العمليات',
    last_reconciliation: 'آخر عملية موازنة',
    retailer_id: 'رقم التاجر',
    is_enabled: 'مفعل؟',
    unsettled_amount: 'القيمة التي لم يتم تسديدها',
    total_unsettled: 'مجموع غير مسدد',
    yes_existing_customer: 'نعم، أنا عميل حالي',
    no_not_existing_customer: 'لا، أنا عميل جديد',
    last_transaction_date: 'تاريخ آخر عملية',
    search_by_payment_placeholder: 'ابحث عن طريق رقم الدفعة..',
    reference_no: 'الرقم',
    amount: 'القيمة',
    statement: 'كشف الحساب',
    pending_amount: 'القيمة المتبقية',
    registration: 'التسجيل',
    registration_existing: 'التسجيل - عميل حالي',
    registration_new: 'التسجيل - عميل جديد',
    register: 'تسجيل',
    cr_number: 'السجل التجاري',
    required_confirm_password: 'تأكيد كلمة المرور مطلوب',
    required_password: 'كلمة المرور مطلوبة',
    placeholder_register_terminal_id: 'أدخل رقم أي جهاز دفع مرتبط بحسابك',
    invalid_cr_no: 'فضلًا أدخل رقم سجل تجاري صحيح',
    verification: 'تفعيل المستخدم',
    resend_verification_code: 'إعادة إرسال رمز التفعيل',
    verification_merchant: 'تفعيل التاجر',
    verification_email: 'تفعيل البريد الإلكتروني',
    verification_code_sent_to_email:
        'تم إرسال الرمز بنجاح إلى بريدك الإلكتروني',
    verification_code_sent_to_owner_phone:
        'تم إرسال الرمز بنجاح إلى الجوال المسجل لدينا!',
    placeholder_verification_code: 'أدخل الرمز هنا...',
    failed_to_contact_server:
        'يبدو أن هناك مشكلة في الاتصال بالسيرفر. يرجى التأكد من اتصالك بالإنترنت ثم المحاولة مرة أخرى.',
    dont_have_account: 'غير مسجل؟ سجل نشاطك التجاري على فودكس Pay',
    have_account: 'لديك حساب بالفعل؟',
    login_here: 'سجل الدخول من هنا',
    out_of: 'من إجمالي',
    terminals_are: 'جهاز دفع هو',
    active: 'نشط',
    active_filter: 'مفعل',
    not_active: 'غير نشط',
    disabled: 'غير نشط',
    enabled: 'نشط',
    loading_more_data: 'يتم الآن تحميل المزيد..',
    volume: 'الإجمالي',
    last_recon: 'آخر مزامنة',
    data_load_completed: 'تم استكمال التحميل',
    click_to_refresh: 'اضغط هنا لتحديث الصفحة',
    connectivity_error:
        'يبدو أن هناك مشكلة في الاتصال. فضلًا تأكد من اتصالك بالإنترنت ثم حاول مرة أخرى.',
    export_to_excel: 'تحميل الإكسل',
    forgot_your_password: 'هل نسيت كلمة المرور؟',
    click_here: 'اضغط هنا',
    click_here_to_edit_your_data: 'اضغط هنا لتعديل بياناتك',
    restore_password: 'تعيين كلمة المرور',
    no_data_found: 'لا توجد بيانات',
    change_password: 'تغيير كلمة المرور',
    current_password: 'كلمة المرور الحالية',
    required_current_password: 'كلمة المرور الحالية مطلوبة',
    are_you_an_existing_foodics_pay_customer: 'هل أنت عميل حالي لفودكس باي؟',
    national_id: 'رقم الهوية أو الإقامة',
    invalid_national_id_or_iqama: 'فضلًا أدخل رقم هوية أو إقامة صحيح',
    invalid_national_id_or_iqama_numeric:
        'رقم الهوية أو الإقامة يجب أن يحتوي على أرقام فقط',
    invalid_national_id_or_iqama_length:
        'رقم الهوية أو الإقامة يجب أن يتكون من 10 أرقام',
    invalid_national_id_or_iqama_start_with:
        'رقم الهوية أو الإقامة يجب أن يبدأ بالرقم 1 أو 2',
    required_national_id_or_iqama: 'الهوية الوطنية أو الإقامة مطلوبة',
    vat_statements: 'بيانات الضريبة',
    vat_statement_information: 'تفاصيل بيان الضريبة',
    download_statement: 'تحميل البيان',
    all: 'الكل',
    mada: 'مدى',
    'visa/mastercard': 'فيزا/ماستر كارد',
    amex: 'أمريكان إكسبريس',
    other_cards: 'بطاقات أخرى',
    tax_invoice: 'الفاتورة الضريبية',
    search_by_invoice_placeholder: 'ابحث عن طريق رقم الفاتورة..',
    search_transaction_placeholder: 'ابحث عن طريق رقم العملية..',
    card_type: 'نوع البطاقة',
    status: 'الحالة',
    transaction_sequential_number: 'الرقم التسلسلي للعملية',
    retailer_name: 'اسم التاجر',
    masked_card_number: 'رقم البطاقة المحتجب',
    transaction_date: 'تاريخ العملية',
    message_type_description: 'وصف نوع الرسالة',
    merchant_fee: 'رسوم التاجر',
    vat_amount: 'قيمة الضريبة',
    net_amount: 'المبلغ الصافي',
    authorization_code: 'رمز الموافقة',
    aggregator_bank: 'البنك',
    approval_code: 'رمز الموافقة',
    email_or_username: 'البريد الإلكتروني أو اسم المستخدم',
    branches: 'الفروع',
    search_branches: 'ابحث عن طريق رقم الفرع..',
    change_email: 'تغيير البريد الإلكتروني',
    confirm_email: 'تأكيد البريد الإلكتروني',
    invoice_no: 'رقم الفاتورة',
    year: 'السنة',
    invoice: 'الفاتورة',
    branch_no: 'رقم الفرع',
    is_active: 'مفعل',
    is_main: 'الفرع الرئيسي',
    total_vat: 'إجمالي مبلغ ضريبة القيمة المضافة',
    unreconciled_amount: 'القيمة التي لم يتم موازنتها',
    otp_code: 'رمز التفعيل',
    owner_name: 'الاسم الكامل للمالك',
    gender: 'الجنس',
    birth_date: 'تاريخ الميلاد',
    contact_name: 'الأسم الكامل لمسؤول التواصل',
    contact_national_id: 'رقم هوية أو إقامة مسؤول التواصل',
    mcc: 'نوع الرخصة',
    municipality_number: 'رقم البلدية',
    business_type: 'نوع العمل',
    bank: 'البنك',
    account_title: 'اسم المستفيد',
    account_number: 'رقم الحساب',
    account_iban: 'رقم الأيبان',
    branch_name: 'اسم الفرع',
    zip_code: 'الرمز البريدي',
    branch_address: ' عنوان الفرع باللغة الانجليزيه',
    building: 'المبنى',
    po_box: 'الصندوق البريدي',
    required_owner_name: 'اسم المالك مطلوب',
    required_gender: 'تحديد الجنس مطلوب',
    required_birth_date: 'تاريخ الميلاد مطلوب',
    required_mcc: 'نوع الرخصة مطلوب',
    required_cr_number: 'رقم السجل التجاري/رخصة العمل الحر مطلوب',
    required_municipality_number: 'رقم البلدية مطلوب',
    required_business_type: 'نوع النشاط التجاري مطلوب',
    required_bank_id: 'اسم البنك مطلوب',
    required_account_title: 'اسم المستفيد مطلوب',
    invalid_account_title: 'عنوان الحساب غير صحيح',
    required_account_number: 'رقم الحساب مطلوب',
    invalid_account_number: 'فضلًا أدخل رقم حساب صحيح',
    required_account_iban: 'رقم الأيبان مطلوب',
    invalid_iban: 'الرجاء إدخال رقم آيبان سعودي صحيح',
    step_identity_verification: 'التحقق من الهوية',
    step_otp: 'التفعيل',
    step_owner_contact_info: 'المالك ومعلومات الإتصال',
    step_email_verification: 'التحقق من البريد الإلكتروني',
    step_set_password: 'تعيين كلمة مرور',
    step_terms_and_conditions: 'البنود والشروط',
    prev: 'الرجوع للخلف',
    next: 'التالي',
    finish: 'الانتهاء',
    create_new_password: 'قم بتعيين كلمة مرور جديدة لإتمام إنشاء حسابك',
    password_instructions: 'شروط كلمة المرور',
    at_least: 'تضم على الأقل',
    one_lower_case: 'حرفاً واحدا صغيراً [a-z]',
    one_upper_case: 'حرفاً واحداً كبيراً [A-Z]',
    one_numeric_character: 'رقماً واحداً [0-9]',
    one_special_character: 'رمزاً واحداً: {special_character}',
    password_must_be_at_least: 'يجب أن تتكون من',
    must_contain: 'يجب أن تحتوي',
    password_must_contain_special_character:
        'رمز خاص واحد (مثل {special_character})',
    password_minimum_length: '10 حروف أو رموز على الأقل',
    reset_your_password: 'تعيين كلمة المرور',
    new_email: 'البريد الإلكتروني الجديد',
    new_password: 'كلمة المرور الجديدة',
    required_confirm_email: 'تأكيد البريد الإلكتروني مطلوب',
    email_mismatch: 'يجب أن يتطابق البريد الإلكتروني مع تأكيده',
    verify: 'تفعيل',
    foodics_pay_registration: 'تسجيل فودكس باي',
    company_information: 'معلومات الشركة',
    owner_information: 'معلومات المالك',
    bank_account_details: 'الحساب البنكي',
    contact_information: 'بيانات التواصل',
    required_merchant_name_arabic: 'اسم التاجر بالعربية مطلوب',
    invalid_merchant_name_arabic: 'الاسم يجب أن يحتوي حروفًا عربية',
    required_merchant_name: 'اسم التاجر مطلوب',
    invalid_merchant_name: 'الاسم يجب أن يحتوي حروفًا إنجليزية',
    required_city: 'المدينة مطلوبة',
    required_address: 'العنوان مطلوب',
    required_cr_certificate: 'فضلًا ارفع صورة الرخصة التجارية',
    cr_certificate: 'صورة الرخصة التجارية',
    required_owner_mobile: 'رقم جوال المالك مطلوب',
    invalid_owner_mobile: 'رقم الجوال غير صحيح',
    owner_mobile: 'رقم جوال المالك',
    owner_national_id: 'رقم الهوية الوطنية أو الإقامة',
    required_owner_national_id: 'رقم الهوية الوطنية أو الإقامة للمالك مطلوبة',
    required_bank: 'فضلًا اختر البنك',
    required_beneficiary_name: 'اسم صاحب الحساب مطلوب',
    inactive: 'غير مفعل',
    invalid_beneficiary_name:
        'يجب أن يحتوي الأسم على أحرف أو مسافات عربية أو إنجليزية أو ارقام فقط',
    required_iban: 'رقم الأيبان مطلوب',
    required_iban_certificate: 'فضلًا قم برفع صورة شهادة الأيبان',
    iban_certificate: 'شهادة الأيبان',
    contact_phone: 'رقم الجوال',
    required_contact_national_id:
        'الهوية أو الإقامة الوطنية للمسؤول عن التواصل مطلوبة',
    required_contact_phone: 'رقم جوال مسؤول التواصل مطلوب',
    required_contact_name: 'اسم مسؤول التواصل مطلوب',
    male: 'ذكر',
    female: 'أنثى',
    invalid_birth_date:
        'تاريخ الميلاد غير صحيح (فضلًا استخدم الصيغة 1986-09-22)',
    my_tickets: 'تذاكري',
    ticket_submitted_successfully: 'تم تسجيل التذكرة بنجاح',
    ticket_not_submitted:
        'حصل أمر غير متوقع! تذكرتك لم يتم تسجيلها حاول مرة أخرى',
    new_ticket: 'تذكرة جديدة',
    filter: 'تصفية',
    ticket_id: 'رقم التذكرة',
    ticket_details: 'تفاصيل التذكرة',
    support_chat: 'الدعم',
    send: 'إرسال',
    new_request: 'تذكرة جديدة',
    submit_ticket: 'ارسال التذكرة',
    upload: 'إرفاق ملف',
    say_something: 'اكتب شيئًا هنا... ',
    apply: 'تطبيق',
    clear: 'مسح',
    ticket_status: 'حالة التذكرة',
    tickets: 'تذاكري',
    foodics_address:
        'شارع الامام سعود ابن فيصل, الرياض 13515 ,الدور الثاني, الرياض, المملكة العربية السعودية.',
    ticket_submit_reply: 'تم تسجيل الرد بنجاح',
    email_placeholder: 'ادخل البريد الإلكتروني',
    password_placeholder: 'ادخل كلمة المرور',
    linkedin: 'لينكد ان',
    twitter: 'تويتر',
    facebook: 'فيسبوك',
    instagram: 'انستقرام',
    forget_password: 'نسيت كلمة المرور؟',
    enter_email_account:
        'يرجى كتابة عنوان بريدك الإلكتروني المسجل لدينا لإرسال رابط آمن لمرة واحدة لإعادة تعيين كلمة المرور الخاصة بك',
    are_you_an_existing: 'هل أنت عميل حالي في',
    foodics_pay: 'فودكس باي',
    '?': '؟',
    enter_your_name: 'أدخل اسمك',
    enter_your_email: 'أدخل بريدك الإلكتروني',
    enter_your_phone: 'أدخل رقم هاتفك',
    enter_your_cr: 'أدخل رقم السجل التجاري',
    verified_identity: 'لقد تم التحقق من هويتك.',
    verified_email: 'لقد تم التحقق من بريدك الإلكتروني.',
    password_already_set: 'لقد قمت بتعيين كلمة مرور.',
    owner_is_the_contact_person: 'المالك هو الشخص المسؤول عن التواصل',
    contact_mobile_number: 'رقم جوال مسؤول التواصل',
    enter_full_name: 'أدخل الإسم الكامل',
    select_birthday: 'تاريخ ميلادك',
    select_gender: 'اختر الجنس',
    enter_national_id: 'أدخل رقم الهوية الوطنية أو الإقامة',
    enter_mobile_number: 'أدخل رقم الجوال',
    enter_email_address: 'أدخل البريد الإلكتروني',
    step_license_info: 'معلومات الرخصة',
    select_mcc: 'اختر نوع الرخصة',
    enter_cr: 'أدخل رقم السجل التجاري أو رخصة العمل الحر',
    enter_business_name: ' أدخل اسم العمل باللغة الانجليزيه',
    select_business_type: 'اختر نوع العمل',
    business_has_a_f5_account: 'العميل لديه حساب في فودكس (F5)',
    enter_business_reference: 'أدخل رقم حساب (F5)',
    business_reference: 'رقم حساب فودكس (F5)',
    enter_account_number: 'أدخل رقم الحساب',
    upload_cr: 'أرفق ملف للسجل التجاري',
    business_name: 'اسم العمل',
    step_bank_info: 'معلومات الحساب البنكي',
    bank_account_info: 'معلومات الحساب البنكي',
    bank_info_added_later: 'المعلومات البنكية يمكن إضافتها لاحقاً',
    enter_beneficiary_name: 'أدخل اسم المستفيد',
    select_bank: 'أختار اسم البنك',
    enter_account_iban: 'أدخل رقم الأيبان',
    upload_iban: 'أرفق صورة الأيبان',
    step_branch_info: 'معلومات الفروع',
    branch_info_added_later: 'معلومات الفروع يمكن إضافتها لاحقًا',
    enter_branch_name: 'أدخل اسم الفرع',
    select_city: 'اختر المدينة',
    enter_branch_address: ' أدخل عنوان الفرع باللغة الانجليزيه',
    enter_zipcode: 'أدخل الرمز البريدي',
    invalid_zipcode: 'الرمز البريدي غير صحيح',
    number_of_terminals: 'عدد أجهزة نقاط البيع ',
    enter_terminal_number: 'أدخل عدد أجهزة نقاط البيع',
    upload_branch_photo: 'أرفق صورة للفرع',
    required_branch_name: 'اسم الفرع مطلوب',
    required_city_id: 'مدينة الفرع مطلوبة',
    required_branch_address: 'عنوان الفرع مطلوب',
    required_zip_code: 'الرمز البريدي مطلوب',
    required_terminal_number: 'عدد الأجهزة مطلوب',
    required_business_name: 'اسم النشاط التجاري مطلوب',
    read_and_agree_on: 'لقد قرأت وأوافق علي',
    terms_and_conditions: 'البنود والشروط',
    personal_data_processing_agreement: 'اتفاقیة معالجة البیانات الشخصیة',
    privacy_policy: 'سیاسة الخصوصیة',
    read_the_condition: 'لقد قرأت البنود والشروط وأوافق عليها',
    information_is_correct: 'أقر بأن جميع المعلومات التي كتبتها صحيحة وتامة',
    registration_successful: 'تمت عملية التسجيل بنجاح',
    thanks_text: 'شكرًا لك. لقد تم إرسال بريد إلكتروني على ',
    activate_text: 'لذا يرجى النقر على الرابط الذي في الرسالة لتفعيل حسابك.',
    extra_text: 'الرجاء ملاحظة أن عملية معالجة طلبك لن تبدأ قبل تفعيل حسابك.',
    back_to_login: 'العودة لتسجيل الدخول',
    email_sent_successful: 'تم إرسال رسالة على البريد الإلكتروني بنجاح',
    password_reset_request_sent: 'تم طلب إعادة تعيين كلمة المرور بنجاح',
    password_reset_sent: `قمنا بإرسال بريد إلكتروني إلى {email} لإعادة تعيين كلمة المرور الخاصة بك. يرجى اتباع الخطوات المذكورة ومحاولة الدخول من جديد.`,
    password_reset_sent_note:
        'ملاحظة: إذا لم يتم العثور على البريد الإلكتروني، يرجى التحقق من مجلد البريد العشوائي أو الاتصال بفريق دعم العملاء وتقديم عنوان بريدك الإلكتروني المسجل لدينا.',
    please_enter_otp: 'الرجاء إدخال رمز التفعيل المرسل إلى',
    enter_otp: 'أدخل رمز التفعيل',
    required_otp: 'رمز التفعيل مطلوب',
    invalid_otp: 'رمز التفعيل غير صحيح. فضلًا حاول مرة أخرى',
    required_business_reference: 'مطلوب F5 رقم حساب الـ',
    invalid_business_reference: 'غير صحيح F5 رقم حساب الـ',
    step: 'خطوة',
    of: 'من',
    invalid_crCertificate: 'فضلًا أرفق صورة الرخصة التجارية',
    invalid_ibanEvidence: 'فضلًا أرفق شهادة الأيبان',
    invalid_branchPhotos: 'فضلًا أرفق صور الفرع',
    allowed_extensions:
        'الملف المراد رفعه يجب أن يكون بصيغة JPEG, JPG, PNG, PDF',
    maximum_file_size: '1MB الحد الأقصى لحجم الملف المراد رفعه',
    latin_characters: 'مسموح بالأحرف اللاتينية فقط',
    contact_info: 'معلومات مسؤول التواصل',
    owner_email: 'البريد الإلكتروني للمالك',
    contact_email: 'البريد الألكتروني لمسؤول التواصل',
    required_contact_email: 'البريد الإلكتروني لمسؤول التواصل مطلوب',
    enter_new_password: 'أدخل كلمة المرور الجديدة',
    're-enter-new-password': 'إعادة ادخال كلمة المرور الجديدة',
    current_email_placeholder: 'ادخل البريد الإلكتروني الحالي',
    new_email_placeholder: 'ادخل البريد الإلكتروني الجديد',
    enter_current_password_placeholder: 'أدخل كلمة المرور الحالية',
    enter_new_password_placeholder: 'أدخل كلمة مرور جديدة',
    enter_new_re_enter_password_placeholder: 'إعادة إدخال كلمة المرور الجديدة',
    password_register: 'كلمة المرور',
    placeholder_password: 'أدخل كلمة المرور',
    placeholder_confirm_password: 'أدخل تأكيد كلمة المرور',
    required_email_registration: 'البريد الإلكتروني مطلوب',
    account_verification: 'تأكيد الحساب',
    'resend-otp-code': 'إعادة إرسال رمز التفعيل',
    'minutes-left': 'دقائق متبقية',
    'hours-left': 'ساعات متبقية',
    please_read_the_condition:
        'الرجاء قراءه البنود والشروط من خلال الرابط التالي :',
    foodics_terms_and_condition: 'البنود والشروط لفودكس',
    prefer_lang: 'لغة التواصل المفضلة',
    english: 'إنجليزي',
    arabic: 'عربي',
    select_prefer_lang: 'اختر لغة التواصل المفضلة',
    required_prefer_lang: 'لغة التواصل المفضلة مطلوبة',
    invalid_branch_name: 'فضلا أدخل اسم فرع صحيح',
    invalid_branch_address: 'فضلا أدخل عنوان فرع صحيح',
    set: 'تعيين',
    max_char: 'الحد الأقصى لعدد الأحرف هو 40 حرفًا لاسم العمل',
    min_char: 'الحد الأدنى لعدد الأحرف هو 3 أحرف لاسم العمل',
    days: 'ايام',
    months: 'شهور',
    weeks: 'اسابيع',
    settled: 'في الحساب',
    unsettled: 'لم يتم تسديده',
    unreconciled: 'لم يتم موازنته',
    settled_transactions: 'المعاملات التي تمت تسديدها',
    unsettled_transactions: 'المعاملات التي لم تتم تسديدها',
    unreconciled_transactions: 'المعاملات التي لم تتم تسديدها',
    total_fees_amount: 'إجمالي مبلغ الرسوم',
    card_schemes: 'نظام بطاقة الدفع',
    enable_card_scheme: 'طرق الدفع المفعلة',
    payable_amount: 'المبلغ المستحق',
    max_char_business_name: 'الحد الأقصى لعدد الأحرف هو 40 حرفًا لاسم العمل',
    min_char_business_name: 'الحد الأدنى لعدد الأحرف هو 5 أحرف لاسم العمل',
    min_char_contact_us_message: 'الحد الأدنى لعدد الأحرف هو 3 أحرف للرسالة',
    account_status: 'حالة الحساب',
    welcome_to_foodics_pay: 'أهلا بك في فودكس Pay!',
    go_to_dashboard: 'الذهاب إلى لوحة التحكم',
    status_step_1:
        'شكراً على اكمال برنامج تسجيل فودكس باي. لقد تلقينا طلبك وهو قيد المعالجه.',
    status_step_2:
        'سيتم مراجعة الطلب الخاص بك وسيتم تكوينه بمجرد التحقق منه. عادةً ما تستغرق عملية التحقق من يوم إلى ثلاثة أيام عمل ، إذا كانت هناك حاجة إلى مزيد من التوضيح من جانبنا ، فسيقوم فريقنا بالاتصال بك.',
    status_step_3:
        'حسابك قيد التكوين ، ونحن نعمل على تكوين حسابك مع البنك. عادةً ما تستغرق عملية التحقق من يوم إلى ثلاثة أيام عمل ، إذا كانت هناك حاجة إلى مزيد من التوضيح من جانبنا ، فسيقوم فريقنا بالاتصال بك.',
    status_step_4:
        'تمت الموافقة على طلبك وتفعيله. يجب أن تكون قد تلقيت رسالة نصية قصيرة على الرقم المسجل. يرجى الاتصال بالفريق للحصول على الدعم',
    status_not_validated: 'لم يتم التحقق من صحة طلبك للسبب التالي :',
    thank_you: 'شكرا لك.',
    invalid_name: 'فضلاً ادخل اسم صحيح',
    max_char_name: 'الحد الأقصى لعدد الأحرف هو ٤٠ حرفاً لخانة الأسم',
    min_char_name: 'الحد الأدنى لعدد الأحرف هو ٣ أحرف لخانة الأسم',
    max_char_account_beneficiary_name:
        'الحد الأقصي لعدد الأحرف هو ٣٥ حرفاً لأسم المستفيد',
    max_char_account_number: 'الحد الأقصي لعدد الأحرف هو ٢٤ حرفاً لرقم الحساب',
    min_char_account_beneficiary_name:
        'الحد الأدني لعدد الأحرف هو ٥ أحرف لأسم المستفيد',
    min_char_account_number: 'الحد الأدني لعدد الأحرف هو ١٠ أحرف لرقم الحساب',
    max_char_branch_name: 'الحد الأقصي لعدد الأحرف هو ٤٠ حرفاً لأسم الفرع',
    min_char_branch_name: 'الحد الأدني لعدد الأحرف هو ٣ أحرف لأسم الفرع',
    account_number_length: 'يجب أن يتراوح طول رقم الحساب بين 10 و24 رمزاً',
    beneficiary_name_length: 'يجب أن يتراوح طول اسم المستفيد بين 5 و 35 رمزاً',
    cp_beneficiary_name: 'اسم المستفيد',
    data_not_found: 'لم يتم العثور على بيانات',
    branch: 'الفرع',
    terminal: 'جهاز نقاط البيع',
    from: 'من',
    to: 'الى',
    verification_mobile: 'تحقق من رقم الهاتف',
    otp_code_sent_to_mobile: 'تم إرسال الرمز بنجاح إلى رقم الهاتف المسجل',
    resend_otp_code: 'اعاده إرسال رمز التحقق',
    search_ticket_number_placeholder: 'ابحث عن طريق رقم التذكره',
    foodics_pay_customer_portal: 'بوابة عملاء فودكس باي ',
    yes_terminal_active: 'نعم، الجهاز نشط',
    no_terminal_active: 'لا، الجهاز غير نشط',
    number_of_branches: 'عدد الفروع',
    required_branches_number: 'عدد الفروع مطلوب',
    payment_option: 'طريقه الدفع',
    required_payment_option: 'طريقه الدفع مطلوبة',
    min_num_terminals: 'الحد الأدنى لعدد الأجهزة هو ١',
    min_num_brunches: 'الحد الأدنى لعدد الأفرع هو ١',
    change_email_successfully:
        'تم تغيير البريد الألكتروني بنجاح. سيتم تسجيل خروجك الآن. رجاء أعد تسجيل الدخول بالبريد الإلكتروني الجديد.',
    change_password_successfully: 'تم تغيير كلمة المرور بنجاح.',
    changed_successfully: 'تم التغيير بنجاح',
    transaction_take_time:
        'تستغرق المعاملات يوم عمل واحد تقريباً لتظهر هنا. بإمكانك مطالعة المعاملات التي تمت تسديدها في آخر 12 شهرًا فقط. فإذا كنت تريد عرض المعاملات قبل تلك الفترة، يرجى النقر على  المعاملات المؤرشفة.',
    foodics_online_registration: 'تسجيل فودكس باي أونلاين',
    max_char_ticket_message: 'الحد الأقصي لعدد الأحرف للرساله هو ٢٠٠٠ حرف',
    application_received: 'تم استلام الطلب',
    application_validation: 'التحقق من الطلب',
    account_configuration: 'تكوين الحساب',
    account_approval: 'الموافقه على الحساب',
    account_rejected: 'الحساب مرفوض',
    enter_merchant_name_in_arabic: 'ادخل اسم التاجر',
    enter_merchant_name_in_english: 'ادخل اسم التاجر باللغه الانجليزيه',
    enter_address: 'ادخل العنوان',
    enter_number_of_branches: 'ادخل عدد الافرع',
    select_payment_option: 'اختار طريقه الدفع',
    'ticket-attachment': 'مرفق التذكره',
    attachment: 'مرفقات',
    'required-documents':
        'يرجى تجهيز المستندات / المعلومات التالية للتحميل بأحد الصيغات التالية: ملفات JPEG أو JPG أو PNG أو PDF.',
    iban_document: 'ملف ايبان',
    cr_freelance_license: 'رخصه للسجل التجاري',
    branch_photo: 'صور للفرع',
    using_foodics_pay: 'انا استخدم بالفعل اجهزه نقاط البيع الخاصه بفودكس باي',
    clear_all: 'امسح الكل',
    filters: 'تصفيه',
    business_name_in_english: 'اسم العمل يجب ان يكون باللغه الانجليزيه',
    business_name_in_arabic: 'اسم العمل يجب ان يكون باللغة العربيه',
    address_in_english: 'العنوان يجب ان يكون باللغة الانجليزيه',
    address_in_arabic: 'العنوان يجب ان يكون باللغة العزبيه',
    business_name_ar: 'اسم العمل باللغة العربيه',
    enter_business_name_ar: 'ادخل اسم العمل باللغة العربيه',
    branch_address_ar: 'عنوان الفرع باللغة العربيه',
    enter_branch_address_ar: 'عنوان الفرع باللغة العربيه',
    terminal_take_time:
        'قد تستغرق معلومات اجهزه نقاط البيع من 1 إلى 3 أيام عمل لتنعكس هنا',
    version: 'نسخة',
    required_login_email: 'البريد الألكتروني مطلوب',
    delete_branch_alert: 'هل أنت متأكد أنك تريد حذف هذا الفرع؟',
    delete: 'حذف',
    add_branch: 'اضافه فرع',
    bank_account: 'حساب البنك',
    select_bank_account: 'اختيار حساب البنك',
    required_bank_account: 'حساب البنك مطلوب',
    add_bank: 'اضافه حساب بنكي',
    delete_confirmation: 'تأكيد الحذف',
    edit_bank: 'تعديل البنك',
    edit_branch: 'تعديل الفرع',
    delete_bank_alert: 'هل انت متأكد انك تريد حذف هذا البنك؟',
    delete_bank_account_alert: 'هل انت متأكد انك تريد حذف هذا الحساب البنكي؟',
    required_mobile_dial_code: 'رمز الجوال مطلوب',
    dashboard_menu: 'لوحة البيانات',
    transactions_menu: 'العمليات',
    payments_menu: 'الدفعات',
    statements_menu: 'البيانات و الفواتير',
    vat_statements_menu: 'بيانات الضريبة',
    vat_invoices_menu: 'فواتير ضريبة القيمة المضافة',
    account_statement_menu: 'كشف الحساب',
    branches_menu: 'الفروع',
    manage_menu: 'ادارة',
    users_menu: 'المستخدمون',
    terminal_recon_menu: 'تسوية الأجهزة',
    bank_accounts_menu: 'الحسابات البنكية',
    reports_menu: 'التقارير',
    bank_reconciliation_report_menu: 'تقرير التسوية البنكية',
    new: 'جديد',
    beta: 'قيد التجربة',
    terminal_id16: 'رقم الجهاز ١٦',
    card_scheme: 'نظام بطاقه الدفع',
    transaction_fees: 'رسوم المعاملة',
    currency: 'العملة',
    order_reference: 'مرجع الطلب',
    integrated: 'دمج',
    settleable: 'قابل للتسويه',
    not_settleable_reason: 'سبب غير قابل للتسويه',
    terminal_reference: 'مرجع جهاز نقاط البيع',
    payment_reference: 'مرجع الدفع',
    view_receipt: 'عرض الإيصال',
    payment: 'الدفع',
    reference: 'رقم المرجعي',
    credit_bank: 'البنك الأنتمائي',
    bank_status_message: 'حاله البنك',
    transfer_date: 'تاريخ العمليه',
    debit_bank_short_code: 'رمز قصير لبنك المدين',
    debit_account_number: 'رقم حساب المدين',
    debit_beneficiary_name: 'اسم المستفيد المدين',
    credit_bank_short_code: 'رمز قصير لبطاقه البنك',
    bank_reference: 'الرقم المرجعي للبنك',
    credit_account_number: 'رقم البنك الائتماني',
    credit_beneficiary_name: 'اسم المستفيد لبطاقة الائتمان',
    credit_beneficiary_address: 'عنوان المستفيد لبطاقه الائتمان',
    external_reference: 'المرجع الحارجي',
    return_date: 'تاريخ الأرجاع',
    return_reason: 'سبب الأرجاع',
    transactions_count: 'عدد العمليات',
    transactions_amount: 'كمية العمليات',
    transactions_net_amount: 'المجموع الكلي للعمليات',
    deductions_amount: 'مجموع التعديلات',
    payment_no_transactions: 'هذه الدفعة ليس لها معاملات معينة حتى الآن',
    bank_fee: 'رسومات البنك',
    foodics_fee: 'رسومات فودكس',
    installed: 'مثبت',
    pulled_out: 'انسحب',
    merchant: 'تاجر',
    yes: 'نعم',
    no: 'لا',
    sim_card_provider: 'مزود بطاقة SIM',
    trsm: 'الرقم التسلسلي للجهاز',
    installation_request_date: 'تاريخ طلب التثبيت',
    installation_date: 'تاريخ التثبيت',
    pullout_request_date: 'تاريخ طلب سحب',
    pullout_date: 'تاريخ السحب',
    last_reconciliation_date: 'تاريخ اخر موازنة',
    device_information: 'معلومات الجهاز',
    serial_number: 'الرقم التسلسلي',
    vendor: 'بائع',
    model: 'نموذج',
    firmware_version: 'اصدار البرنامج',
    manufacture_date: 'تاريخ الصنع',
    end_of_life_date: 'تاريخ نهايه الجهاز',
    merchant_information: 'معلومات التاجر',
    subscription: 'اشتراك',
    device: 'الجهاز',
    subscription_information: 'معلومات الاشتراك',
    contract_type: 'نوع العقد',
    start_date: 'تاريخ البدء',
    end_date: 'تاريخ الانتهاء',
    terminals_menu: 'الأجهزة',
    new_customer_status_menu: 'الحاله',
    are_you_sure_you_want_to_disable_this:
        'هل انت متأكد انك تريد تعطيل هذا الجهاز ؟',
    showing: 'اظهار',
    processed: 'تم معالجته',
    failed: 'فشل',
    payment_information: 'معلومات الدفع',
    terminal_information: 'معلومات الجهاز',
    transaction_information: 'معلومات العمليه',
    invalid_zib_code: 'الرمز البريدي غير صحيح',
    are_you_sure_you_want_to_delete_this: 'هل انت متأكد انك تريد حذف هذا؟',
    invalid_terminal_counts: 'يجب أن يكون العد النهائي بين ١ و ١٠٠',
    invalid_luhn_check: 'تأكد من إدخال قيمة صحيحة.',
    invalid_full_name:
        'يجب أن يحتوي الاسم على الاسم الأول والأخير. يتكون كل اسم من حرفين على الأقل ولا يمكن أن يحتوي على أحرف غير أبجدية.',
    add_btn: 'أضف',
    update_btn: 'تعديل',
    min_age: 'يجب ألا يقل العمر عن 18 عامًا',
    required_terms_accepted:
        'يرجى الإشارة إلى أنك قد قرأت شروط وأحكام فودكس وافقت عليها',
    required_data_processing_accepted:
        'يرجى الإشارة إلى أنك قد قرأت اتفاقیة معالجة البیانات الشخصیة فودكس وافقت عليها',
    required_privacy_policy_accepted:
        'يرجى الإشارة إلى أنك قد قرأت سیاسة الخصوصیة وافقت عليها',
    required_certify_accepted:
        'يرجى تأكيد أن المعلومات التي قمت بكتابتها صحيحة وتامة',
    created_at: 'تم إنشائه في',
    f5_branch_id: 'معرف F5 للفرع',
    branch_information: 'معلومات الفرع',
    under_review: 'قيد المراجعه',
    payment_transactions: 'معاملات الدفع',
    owner_birthdate: 'التاريخ الميلادي للمالك',
    owner_mobile_number: 'رقم الهاتف للمالك',
    profile: 'الملف الشخصي',
    accounts_menu: 'الحسابات',
    confirm_new_password: 'تأكيد كلمة المرور الجديده',
    current_email_address: 'البريد الألكتروني الحالي',
    new_email_address: 'البريد الألكتروني الجديد',
    password_changed_successfully: 'تم تغيير كلمة المرور بنجاح',
    are_you_sure_change_password: 'هل انت متأكد انك تريد تغيير كلمة المرور ؟',
    email_changed_success: 'تم تغيير البريد الإلكتروني بنجاح.',
    password_changed_success: 'تم تغيير كلمة المرور بنجاح.',
    mada_card_fee: 'رسوم بطاقه مدى',
    total_payments: 'مجموع الدفعات',
    total_net_amount: 'إجمالي المبلغ الصافي',
    total_pending: 'مجموع قيد المراجعة',
    total_new: 'مجموع الجديد',
    total_submitted: 'مجموع تم تقديمه',
    total_under_review: 'مجموع تحت المراجعة',
    count: 'العدد',
    date_range: 'فترة زمنية',
    transaction_date_range: 'النطاق الزمني للمعاملة',
    transactions_dashboard: 'عمليات',
    payments_dashboard: 'دفعات',
    existing_customer_registered_successfully:
        'شكرًا لك. لقد سجلت بنجاح ، يمكنك تسجيل الدخول الآن',
    otp_sent_successfully: 'تم إرسال كلمة مرور OTP جديدة بنجاح.',
    country_of_birth: 'بلد الميلاد',
    required_country_of_birth: 'بلد الميلاد مطلوب',
    country_of_residence: 'بلد الإقامة',
    required_country_of_residence: 'بلد الإقامة مطلوب',
    source_of_income: 'مصدر الدخل',
    required_source_of_income: 'مصدر الدخل مطلوب',
    profession: 'المهنة',
    required_profession: 'المهنة مطلوبة',
    download_report: 'تحميل التقرير',
    update_successful: 'تم التحديث بنجاح',
    rejected_thanks_text: 'شكراً لكم. تم تحديث معلوماتكم بنجاح.',
    rejected_activate_text: 'بإمكانك الآن العودة إلى حسابك لمتابعة الحالة.',
    back_to_status_page: 'العودة إلى الحساب',
    valid_business_name:
        'يجب ألا يبدأ الاسم بمسافة ، ولا يُسمح إلا باستخدام الأحرف اللاتينية والمسافات والأرقام.',
    valid_vat_registration_number:
        'فضلاً إدخال رقم ضريبي صحيح. يجب أن يحتوي على 15 رقمًا ويبدأ وينتهي بالرقم 3.',
    update_vat_registration_number: 'تم تعديل الرقم الضريبي بنجاح.',
    vat_registration_number_required: 'الرقم الضريبي مطلوب.',
    solo_id: 'Solo ID',
    account_or_cr_number: 'رقم حساب F5 أو السجل التجاري',
    account_or_cr_number_placeholder: 'أدخل رقم حساب F5 أو السجل التجاري',
    required_account_or_cr_number:
        'مطلوب بسبب ارتباط البريد الإلكتروني بعدة تجار',
    an_error_occurred: 'حدث خطأ ما!',
    not_authorized: 'غير مسموح لك القيام بهذه العملية.',
    try_again: 'الرجاء إعادة المحاولة',
    owner_otp: 'OTP المالك',
    contact_otp: 'OTP جهة الاتصال',
    total_fee: 'مجموع الرسوم',
    total_fees_and_vat: 'مجموع الرسوم والضرائب',
    card_number: 'رقم البطاقه',
    original_transaction: 'المعاملة الاصلية',
    reversed_transaction: 'المعاملة المعاكسه',
    is_reversed: 'معكوس',
    credit_account_iban: 'رقم الآيبان لبطاقة الائتمان',
    reason: 'السبب',
    payment_deductions: 'استقطاعات الدفع',
    can_not_serve: 'عذرا، لم نتمكن من تلبية طلبك',
    change_mobile_number: 'تغيير رقم الجوال',
    mobile_number_changed_success: 'تم تغيير رقم الجوال الخاص بك بنجاح',
    create_foodics_pay_account: 'إنشاء حسابك في فودكس Pay',
    start_the_process:
        'لجعل عملية إنشاء الحساب سهلة وسريعة، ننصحك بتأمين نسخة إلكترونية من مستنداتك التجارية والمالية بأحد الصيغ التالية: JPEG, PNG, JPG,PDF',
    here_are_the_prerequisites: 'المتطلبات الأساسية:',
    prerequisites_one: '1. حساب نشط على منصة نفاذ',
    prerequisites_two: '2. سجل تجاري أو مستند عمل حر',
    prerequisites_three: '3. مستند رقم الآيبان الخاص بالعمل التجاري',
    owner_only_note:
        'لا ينبغي لأحد سوى صاحب العمل التجاري أن يقوم بعملية التسجيل هذه.',
    get_started: 'ابدأ',
    nafath_verify_identity: 'التحقق من الهوية مع نفاذ',
    note: 'ملاحظة: ',
    nafath_verification_note:
        'يمكنك إتمام عملية التحقق هذه من خلال موقع نفاذ والتطبيق الجوال',
    enter: 'أدخل',
    and_click: 'وانقر',
    to_start_the_verification: 'لبدء عملية التحقق',
    nafath_verification_instructions: '',
    nafath_otp_instructions:
        'سوف يصلك طلب رقم للتحقق على حسابك في بوابة نفاذ وستجد',
    number_here: ' الرقم المطلوب هنا.',
    select_nafath_number: 'يرجى اختيار هذا الرقم على حسابك في نفاذ',
    do_not_have_nafath_app:
        'إذا لم تكن تستعمل تطبيق نفاذ، يمكنك النقر هنا لاستعمال الخدمة عبر الموقع الإلكتروني',
    nafath_website: 'موقع نفاذ',
    identity_verified: 'تم التحقق من الهوية!',
    identity_not_verified: 'تعذر التحقق من الهوية!',
    check_your_id_try_again:
        'الرجاء التحقق من رقم الهوية ثم الضغط على التالي للمحاولة مرة أخرى.',
    personal_info_description: 'البيانات الشخصية',
    email_verification_description_part1:
        'قمنا بإرسال رمز تحقق لمرة واحدة إلى بريدك الإلكتروني',
    email_verification_description_part2: 'يرجى إدخال الرمز الوارد هنا.',
    license_info_description: 'بيانات العمل التجاري',
    bank_info_description: 'بيانات الحساب البنكي',
    branch_info_description: 'بيانات الفرع',
    set_password_description: 'قم بتعيين كلمة مرور جديدة لإتمام إنشاء حسابك',
    terms_and_conditions_description: 'الشروط والأحكام',
    new_user: 'إضافة مستخدم',
    last_login: 'تاريخ آخر دخول',
    enable_user: 'تنشيط المستخدم',
    disable_user: 'إلغاء تنشيط المستخدم',
    enable_users: 'تنشيط المستخدمين',
    disable_users: 'إلغاء تنشيط المستخدمين',
    users: 'المستخدمون',
    actions: 'الإجراءات',
    user_added_successfully: 'تم إضافة المستخدم بنجاح',
    user_enabled_successfully: 'تم تنشيط المستخدم بنجاح',
    user_disabled_successfully: 'تم إلغاء تنشيط المستخدم بنجاح',
    users_enabled_successfully: 'تم تنشيط المستخدمين بنجاح',
    users_disabled_successfully: 'تم إلغاء تنشيط المستخدمين بنجاح',
    enable_user_confirmation: 'هل أنت متأكد من رغبتك في تنشيط هذا المستخدم؟',
    disable_user_confirmation:
        'هل أنت متأكد من رغبتك في إلغاء تنشيط هذا المستخدم؟',
    enable_users_confirmation:
        'هل أنت متأكد من رغبتك في تنشيط هؤلاء المستخدمين؟',
    disable_users_confirmation:
        'هل أنت متأكد من رغبتك في إلغاء تنشيط هؤلاء المستخدمين؟',
    onboarding_login_hint: 'يمكنك تسجيل الدخول هنا.',
    reset_password: 'تغيير كلمة المرور',
    create_your_login: 'إنشاء تسجيل الدخول',
    role: 'الدور الوظيفي',
    already_have_contact_paerson: 'لديك مستخدم تم تحديده سابقاً كجهة اتصال',
    will_replace_new_contact_person:
        'سوف يتم اعتماد المستخدم الجديد بدلاً عنه.',
    replace_contact_person: 'هل تريد استبداله؟',
    set_as_contact_person: 'تحديد كجهة اتصال',
    contact_person_set_successfully: 'تم تحديد جهة اتصال جديدة بنجاح',
    contact_person: 'جهة الاتصال',
    owner: 'مالك',
    user: 'مستخدم',
    free_days: 'أيام مجانية',
    monthly_fee: 'رسوم شهرية',
    gpv_lower_limit: 'الحد الأدنى للمعاملات',
    gpv_upper_limit: 'الحد الأقصى للمعاملات',
    monthly_fee_vat_exclusive: 'الرسوم الشهرية (غير شامل الضريبة)',
    card_transaction_rate: 'نسبة معاملات البطاقة',
    generate_vat_statement: 'إصدار بيان ضريبة القيمة المضافة',
    generate_and_download: 'إصدار وتنزيل',
    generate_and_export: 'إصدار التقرير وتصديره',
    select_statment_month: 'اختيار شهر البيان',
    group_by_branch: 'تجميع حسب الفرع',
    break_by_transaction: 'تفصيل حسب العملية',
    looking_for_last_month_statment:
        'هل تبحث عن كشف ضريبة القيمة المضافة الخاص بالشهر الماضي؟',
    vat_statement_for_the_previous_month_is_available:
        'سيكون كشف ضريبة القيمة المضافة للشهر السابق متاحًا للتنزيل بدءًا',
    from_the_4th_of_every_month: 'من اليوم الرابع من كل شهر.',
    if_you_are_here_before_then_you_will_not_be_able_to_download_the_statement:
        ' إذا كنت هنا قبل هذا التاريخ، فلن يكون متاحًا للتنزيل بعد.',
    retrieve_vat_invoice: 'إصدار فاتورة ضريبة القيمة المضافة',
    select_invoice_month: 'اختيار شهر الضريبة',
    select_branches: 'اختيار الفروع',
    looking_for_last_monthly_invoice: 'هل تبحث عن فاتورة الشهر الماضي؟',
    vat_invoice_for_previous_month:
        'ستكون فاتورة ضريبة القيمة المضافة للشهر السابق متاحة اعتبارًا',
    not_able_to_export_invoice:
        'إذا كنت هنا قبل ذلك، فلن تتمكن من إصدار الفاتورة.',
    vat_invoice_successfully_generated_and_emailed:
        'يتم تصدير البيانات في الخلفية وسوف تصلك رسالة بريد إلكتروني عند الانتهاء من عملية التصدير.',
    data_being_exported: 'يتم تصدير البيانات',
    branch_is_required: 'حقل الفرع مطلوب',
    branch_reference: 'رقم الفرع',
    per_terminal_vat: 'ريال سعودي في الشهر لكل جهاز (غير شامل الضريبة)',
    subscription_fee: 'أجرة الاشتراك',
    rate: 'رسوم البطاقة',
    subscription_fee_note:
        '*سوف يتم إعفاؤك من رسوم الاشتراك في أي شهر يتجاوز معدل قيمة المعاملات فيه لكل جهاز 15000 ريالاً سعودياً',
    required_month: 'يرجى اختيار شهر',
    generate_vat_invoice_within_2_years:
        'يرجى ملاحظة أنه باستطاعتك استصدار فواتير الضريبة لآخر 12 شهرًا فقط',
    generate_vat_statement_within_2_years:
        'يرجى ملاحظة أنه باستطاعتك استصدار بيانات ضريبية لآخر 12 شهرًا فقط',
    vat_statment_successfully_generated_and_emailed:
        'يتم إعداد بيان ضريبة القيمة المضافة الآن في الخلفية وسوف تصلك رسالة بالبريد الإلكتروني عندما يصبح البيان جاهزاً.',
    to_download_nafath_app: 'لتحميل تطبيق نفاذ',
    my_apps: 'تطبيقاتي',
    required_number_branches: 'يرجى إدخال عدد الفروع',
    invalid_branch_counts: 'عدد الفروع بين 1 ، 250',
    required_number_employees: 'يرجى ادخال عدد الموظفين',
    required_monthly_turn_over: 'يرجى تحديد خيار واحد',
    invalid_freelanceCertificate: 'يرجى تحميل وثيقة العمل الحر',
    invalid_aoaCertificate: 'يرجى تحميل نظام الشركة الأساسي',
    pay: 'Pay',
    console: 'لوحة التحكم',
    online: 'أونلاين',
    step_create_account: 'إنشاء حساب',
    step_nafath_verification: 'التحقق من الهوية بواسطة نفاذ',
    step_business_information: 'بيانات العمل التجاري',
    verify_create_account: 'التحقق وإنشاء حساب',
    ask_you_for: 'سوف نطلب منك الآن:',
    nafath_account: 'حساب نفاذ نشط',
    cr_certificate_freelance: 'شهادة سجل تجاري أو وثيقة عمل حر',
    next_steps_to_order_terminals:
        'في الخطوات اللاحقة، ولتتمكن من طلب أجهزة الدفع، عليك بتزويدنا بمستند ',
    bank_iban_document: 'رقم الآيبان الخاص بحسابك البنكي.',
    only_business_owner_can_complete:
        'لا يمكن لأحد إتمام هذه العملية سوى صاحب العمل التجاري.',
    already_have_pay_account_log_in_here: 'لديك حساب في Pay؟ ادخل من هنا',
    use_ur_email_and_password_to_sign_in_to_foodics_pay:
        'سوف تستخدم بريدك الإلكتروني وكلمة المرور الخاصة بك لمشاهدة البيانات والتقارير على بوابة فودكس Pay.',
    want_to_ad: 'هل ترغب بإدخال أرقام',
    more_that_one_license: 'أكثر من رخصة؟',
    do_that_later: 'يمكنك عمل ذلك لاحقاً من خلال إعدادات الحساب.',
    license_run_under: 'ما هي الرخصة التي يندرج عملك التجاري تحتها؟',
    have_cr_unn_license: 'الرقم الوطني الموحد للمنشأة/للسجل التجاري',
    have_freelance_license: 'أمتلك وثيقة عمل حر',
    commercial_registration_license_number: 'رقمة رخصة العمل التجاري',
    number_of_employees: 'عدد الموظفين',
    monthly_turnover: 'الإيراد الشهري',
    article_of_association: 'تحميل نظام الشركة الأساسي',
    i_certify_that_provided_data_is_true_and_correct:
        'أقر بأن البيانات التي قمت بتزويدها حقيقية وصحيحة',
    freelance_license_number: 'رقم وثيقة العمل الحر',
    upload_cr_certificate: 'تحميل شهادة السجل التجاري',
    upload_freelance_license: 'تحميل وثيقة العمل الحر',
    could_not_download_your_data_from_nafath:
        'لم نتمكن من استرجاع بياناتك عبر منصة نفاذ.',
    could_not_get_the_data:
        'لم نتمكن من استرجاع بياناتك عبر منصة نفاذ. يرجى تزويدنا ببعض المعلومات الأساسية أدناه:',
    full_name: 'الاسم الكامل',
    date_of_birth: 'تاريخ الميلاد',
    more_than: 'أكثر من',
    skip: 'تخطي',
    nafath_verify_identity_description:
        'يرجى التأكد من تثبيت تطبيق نفاذ على هاتفك المحمول وأن حسابك على نفاذ جاهز قبل الانتقال إلى الخطوة التالية.',
    skip_nafath_description:
        'التحقق عبر نفاذ هو متطلب حكومي ينبغي على التاجر القيام به لمتابعة عملية التسجيل. يمكنك إثبات هويتك عبر نفاذ لاحقًا أثناء عملية التسجيل أو',
    retry_now: 'إعادة المحاولة الآن',
    your_identity_not_verified: 'فشلت عملية التحقق من هويتك باستخدام نفاذ.',
    click_here_to_verify_nafaht:
        'إن التحقق عبر نفاذ هو من متطلبات البنك المركزي السعودي (ساما). {click_here} للتحقق مجدداً لمنع أي انقطاع في الخدمة.',
    and_mobile_app: 'ورقم الجوال',
    not_received_otp: 'لم تستلم رمز التحقق إلى الآن؟ عاود إرساله في',
    foodics_online: 'فودكس أونلاين',
    foodics_one: 'One فودكس',
    customer_display_payments: 'مدفوعات شاشة العميل',
    standard_pos_terminal: 'جهاز نقطة بيع',
    excl_vat: 'لا تشمل الضريبة',
    declined_reason: 'سبب الفشل',
    current_view: 'العرض الحالي',
    change_default_bank: 'تغيير حساب هذا الفرع',
    change_bank_account: 'اختر الحساب البنكي',
    main: 'الرئيسي',
    settled_payment: 'في الحساب',
    payout_initiated: 'قيد التحويل',
    bank_information: 'المعلومات البنكية',
    from_bank: 'من بنك',
    to_bank: 'إلى بنك',
    from_beneficiary_name: 'من حساب بإسم',
    to_beneficiary_name: 'إلى حساب بإسم',
    from_account_number: 'من رقم حساب',
    to_account_number: 'إلى رقم حساب',
    from_iban: 'من رقم IBAN',
    to_iban: 'إلى رقم IBAN',
    // Onboarding Terminals Flows
    product_description: {
        status: 'الحالة: ',
        welcome_to_customer_portal: 'مرحباً بك في بوابة مستخدم فودكس باي',
        choose_products: 'اختر المنتجات',
        select_product_types:
            'أولاً، قم باختيار أنواع المنتجات. بإمكانك طلب عدة أنواع أجهزة الآن أو لاحقاً عبر',
        terminal_page: 'صفحة الأجهزة',
        product_details: 'تفاصيل المنتج',
        no_monthly_fees: 'بدون رسوم شهرية',
        foodics_online: 'فودكس أونلاين',
        foodics_one: 'فودكس One',
        customer_display_payments: 'مدفوعات شاشة العميل',
        standard_pos_terminal: 'جهاز نقطة بيع',
    },

    order_summary: {
        multiple_branches: 'عدة فروع',
        confirm_and_order: 'التأكيد وإنشاء الطلب',
        change: 'تعديل',
        Total: 'إجمالي',
        month: 'الشهر',
        payments_fees: 'رسوم المدفوعات',
        not_completed_order:
            'يرجى الرجوع وإصلاح الخطأ أو إلغاء المنتج إذا لم تعد مهتماً بطلبه.',
        please_rates_terms_conditions_1: 'أنا أقبل ',
        please_rates_terms_conditions_2: 'الرسوم والأحكام والشروط الخاصة ',
        please_rates_terms_conditions_3: 'باشتراك فودكس أونلاين.',
        are_you_sure_you_want_to_delete: 'هل انت متأكد انك تريد حذف ',
        please_accept_terms_conditions:
            'الرجاء الموافقة على الشروط والأحكام المتعلقة باشتراك فودكس أونلاين',
        delete_payment_terminal_product_alert:
            'هل انت متأكد انك تريد حذف جهاز الدفع؟',
    },

    product_progress_bar: {
        product_details: 'تفاصيل المنتج',
        choose_branches: 'اختر الفروع',
        link_bank_accounts: 'قم بربط الحسابات البنكية',
    },
    onboarding_card_schemes: {
        MADA: 'مدى',
        VISA_CREDIT: 'بطاقة ائتمانية',
    },
    terminal_product_details: {
        start_your_order: 'إنشاء طلب',
        edit_your_order: 'تعديل الطلب',
    },
    pos_terminal_product: {
        standard_pos_terminal: 'جهاز نقطة بيع',
        pos_termianl_description:
            'قارئ البطاقات هذا يتميز بسهولة حمله وسعره الاقتصادي، وهو يتيح لك قبول عدة أنواع من المدفوعات بما في ذلك إدخال البطاقة في الجهاز أو قراءتها عن بعد دون تلامس.',
        card_payment_rates: 'رسوم البطاقة',
        fixed_amount: 'مقدار ثابت',
        add_to_your_order: 'إضافة إلى الطلب',
        excl_vat: 'لا تشمل الضريبة',
    },
    foodics_online_product: {
        online_license: 'تفعيل بوابة المدفوعات',
        foodics_online_description_1:
            'المنصة الأكثر ملائمة للطلب من مطعمك عبر الإنترنت. تخلص من عمولات تطبيقات التوصيل وابدأ بتمكين عملائك من الطلب منك مباشرة عبر موقع الويب الخاص بك وتطبيق الهاتف المحمول ومنيو الـ QR.',
        foodics_online_description_2: 'إنها فرصتك لامتلاك',
        foodics_online_description_3: 'منصة الطلب الخاصة بمطعمك',
        foodics_online_description_4: 'وبوابة الدفع لدعم فرعك الرقمي الصاعد!',
        online_payment_rates: 'رسوم البطاقات عند الدفع عبر الإنترنت',
    },
    customer_display_product: {
        customer_display_description:
            'شاشة تمنح عملاءك تجربة طلب ودفع استثنائية تمكنهم من تأكيد تفاصيل الطلب وإجراء الدفع دون تلامس (tap to pay).',
    },
    foodics_one_product: {
        foodics_one_description:
            'شغّل عملك التجاري بسهولة باستخدام جهاز واحد فيه نظام كاشير وطابعة سريعة وبوابة دفع آمنة.',
    },
    assign_branches: {
        assign_locations_to_your_terminals: 'حدد أماكن لأجهزتك',
        no_branches_selected:
            'ليس لديك أي فروع مسجلة، يرجى إنشاء فرع لربط الطلب به',
        create_new_branch: 'إنشاء فرع جديد',
        back_to_product_details: 'رجوع إلى تفاصيل المنتج',
        save_branch_information: 'حفظ معلومات الفرع',
        branch_address: 'عنوان الفرع',
        delete_branch: 'حذف الفرع',
        branches_business_locations:
            'الفروع هي أماكن عملك التجاري حيث ستقوم باستخدام أجهزة الدفع.',
        provide_full_address:
            'يرجى كتابة العنوان كاملاً مع الرمز البريدي والمدينة',
        start_choosing_branches:
            'الفروع هي أماكن عملك التجاري، ابدأ من خلال تحديد عدد الفروع التي ترغب بعمل طلب لها.',
        assign_business_branches:
            'الفروع هي أماكن عملك التجاري. يرجى اختيار الفروع التي ترغب بطلب أجهزة لها مع تحديد عدد الأجهزة المطلوب لكل فرع.',
        selected_total_terminal_1: 'لقد قمت بطلب عدد',
        selected_total_terminal_2: 'لجميع فروعك.',
        not_assign_bank_account_to_branches: 'يرجى تحديد حساب بنكي للفرع.',
        minimum_terminals_per_branch: 'يجب تحديد جهاز واحد على الأقل لكل فرع',
        maximum_terminals_per_branch: 'يُسمح {maxValue} أجهزة كحد أقصى لكل فرع',
        this_branch_associated_with_other_products:
            'إن هذا الفرع مرتبط بمنتجات أخرى. هل أنت متأكد من رغبتك بإلغائه؟',
        need_to_select_terminal:
            'ينبغي اختيار جهاز واحد على الأقل لتتمكن من الاستمرار.',
        can_not_assign_POS_terminals_to_branch:
            'يوجد جهاز فودكس ون مرتبط بهذا الفرع وبالتالي لا يمكنك إضافة أجهزة نقاط بيع للفرع.',
        can_not_assign_CDS_terminals_to_branch:
            'يوجد جهاز فودكس ون مرتبط بهذا الفرع وبالتالي لا يمكنك إضافة أجهزة مدفوعات شاشة العميل للفرع.',
        can_not_assign_Foodics_one_terminals_to_branch:
            'هذا الفرغ يمتلك أجهزة أخرى مرتبطة به، ولا يمكنك إضافة جهاز فودكس ون إليه.',
    },
    assign_bank_account: {
        assign_bank_accounts: 'حدد الحسابات البنكية',
        link_bank_description_1:
            'يتم ربط الأجهزة بالفروع، بحيث يكون لكل فرع حساب بنكي معرف وخاص به. كما يتم ربط فودكس باي بالحساب البنكى ',
        to_disburse_payments: 'لاستقبال المدفوعات حين يتم صرفها.',
        link_bank_description_2:
            'سيتم خصم رسوم استخدام الأجهزة من مدفوعاتك في العاشر من كل شهر. لن نقوم بتحصيل أي رسوم قبل تفعيل الأجهزة.',
        link_bank_description_3:
            'يمكنك ربط جميع الفروع والأجهزة بحساب بنكي واحد أو إضافة عدة حسابات بنكية وربطها بفروعك.',
        you_dont_any_registered_bank: 'ليس لديك أي حساب بنكي مسجل.',
        add_new_bank_account: 'إضافة حساب بنك جديد',
        add_a_bank_account: 'إضافة حساب بنكي',
        add_bank_account: 'إضافة حساب بنك',
        edit_bank_account: 'تعديل حساب البنك',
        iban_number: 'رقم الأيبان IBAN',
        delete_bank_account: 'حذف الحساب البنكي',
        save_bank_account_info: 'حفظ معلومات الحساب البنكي',
        save_bank_account: 'حفظ الحساب البنكي',
        back_to_branch_details: 'رجوع إلى تفاصيل الفرع',
        create_bank_account: 'إنشاء حساب بنك جديد',
        not_assign_bank_account_to_branches:
            'يرجى تحديد حساب بنكي لجميع الفروع.',
        all_branches_assign_to_bank_accounts:
            'تم إسناد حسابات بنكية لجميع الفروع.',
        branches_want_link_with_this_account:
            'الفروع التي ترغب بربطها مع هذا الحساب البنكي',
    },
    recieved_order: {
        we_recieved_your_order: 'تم استلام طلبك!',
        we_now_need: 'نحتاج الآن',
        two_four_business_days: 'إلى 2-4 أيام عمل',
        to_verify_the_data_you_provided: 'للتحقق من البيانات التي قدمتها.',
        once_every_thing_is_in_order:
            'بمجرد أن يصبح كل شيء جاهزاً، ستتغير حالة حسابك إلى نشط وسنتصل بك لترتيب تفاصيل التركيب.',
        notify_by_email_1: 'سيتم إخطارك',
        notify_by_email_2: 'عبر البريد الإلكتروني',
        notify_by_email_3: 'بأي تغييرات.',
        forgot_about_something: 'هل نسيت شيئاً؟',
        you_can_order_more_terminals_manage_branches:
            'بإمكانك طلب المزيد من الأجهزة وإدارة فروعك أو إضافة حسابات بنكية أخرى بمجرد الحصول على الموافقة على طلبك الأول.',
        do_you_want_to_delete_your_order: 'هل ترغب بتعديل أو إلغاء الطلب؟',
        please_conact_customer_support:
            'يرجى الاتصال بالدعم الفني على الرقم 920028928 أو استخدام نافذة الدردشة في الأسفل',
        could_not_verify_account: ' لم نستطع التحقق من حسابك!',
        ask_for_additional_info_documents:
            'قد نحتاج أحياناً للحصول على مزيد من المعلومات أو الوثائق. يمكنك معرفة التفاصيل من خلال التواصل مع فريقنا:',
        what_do_you_have_do: 'ما الذي عليك فعله الآن؟',
        send_missing_info_files:
            'يرجى مشاركة المعلومات المتبقية أو الوثائق المطلوبة (حسب التعليمات الواردة أعلاه) عبر العنوان',
        send_missing_info_to_email:
            'كما يمكنك مشاركتها عن طريق الرد على رسالة البريد الإلكتروني التي أرسلناها إليك.',
    },
    // Onboarding Sub Merchant
    payment_terminal_product: {
        payment_terminal: 'جهاز الدفع',
        payment_terminal_description:
            'ارتق بتجربة الدفع في مطعمك من خلال جهاز متعدد الاستخدامات يدعم المعاملات الآمنة للشركات والعملاء.',
    },
    onboarding_already_submitted:
        'تم تقديم هذا الطلب من قبل. يرجى تسجيل الدخول لمعرفة حالة هذا الطلب.',
    enabled_filter: 'مفعل',
    deductions_menu: 'الاستقطاعات',
    subscriptions_menu: 'الاشتراكات',
    loans_menu: 'القروض',
    deductions: {
        title: 'تعديل',
        deductions: 'الاستقطاعات',
        deduction_information: 'معلومات الاستقطاع',
        deduction_details: 'تفاصيل الاستقطاع',
        sub_deduction: 'الاستقطاع الجزئي: {count}',
        all_deductions: 'جميع الاستقطاعات',
        total_amount: 'القيمة الكلية',
        total_applied_amount: 'إجمالي القيمة المطبقة',
        total_remaining_amount: 'إجمالي القيمة المتبقية',
        created_at: 'تاريخ الإنشاء',
        export_pay_subscription: 'Pay تصدير اشتراك',
        export_Compensation: 'تصدير التعويضات',
        export_deduction: 'تصدير الاستقطاعات',
        export_rms_subscription: 'تصدير اشتراك نظام إدارة المطاعم',
        eligible_at: 'تاريخ الاستحقاق',
        applied_payment: 'الدفعة المطبقة',
        remaining_amount: 'القيمة المتبقية',
        applied_amount: 'القيمة المطبقة',
        applied_at: 'تاريخ التطبيق',
        confirmed_at: 'تاريخ التأكيد',
        terminal_id8: 'الجهاز رقم 8',
        type: 'نوع التعديل',
        not_applied: 'غير مطبق',
        sub_deductions: 'الاستقطاعات الجزئية',
        applied_payout: 'الدفعة المطبقة',
        deduction_type: {
            pay_subscription: 'اشتراك Pay',
            compensation: 'التعويضات',
            deduction: 'استقطاع',
            rms_subscription: 'اشتراك نظام إدارة المطاعم',
            supply_loan: 'قرض المورد',
            loan: 'قرض',
        },
        deduction_status: {
            new: 'جديد',
            applied: 'مطبق',
            cancelled: 'ملغى',
            linked: 'متصل',
            partially_applied: 'مطبق جزئياً',
        },
        loans: {
            all: 'جميع القروض',
            all_supply_loan: 'جميع قروض الموردين',
            header: 'القروض',
            title: 'قرض',
            total: 'اجمالي',
            monthly_installment: 'القسط الشهري',
            duration_in_months: 'المدة بالشهور',
            supply_loan: 'قرض المورد',
            export: 'تصدير القروض',
        },
        subscriptions: {
            header: 'الاشتراكات',
            rms: 'اشتراكات نظام إدارة المطاعم',
            pay: 'الاشتراكات',
            all: 'جميع الاشتراكات',
            all_pay: 'جميع اشتراكات Pay',
            all_rms: ' جميع اشتراكات نظام إدارة المطاعم',
        },
    },
    migrated: 'منقول',
    data_is_being_exported:
        'يتم تصدير البيانات في الخلفية وستتلقى بريدًا إلكترونيًا عند الانتهاء من التصدير.',
    select_month: 'اختيار شهر',
    select_duration: 'اختيار المدة',
    archived_transactions: 'المعاملات المؤرشفة',
    transactions_archived: 'المعاملات المؤرشفة',
    original_payment: 'الدفعة الأصلية',
    payment_link: 'رابط الدفعة',
    regenerated_payment: 'الدفعة المرسلة مجدداً',
    regenerated_payment_link: 'رابط الدفعة المرسلة مجدداً',
    return_to_login: 'الرجوع إلى تسجيل الدخول',
    resend_code_in: 'إعادة إرسال الرمز في',
    enter_verification_code: 'قم بإدخال رمز التحقق المكون من 4 أرقام',
    account_locked: 'لقد تم قفل حسابك. يرجى التواصل مع خدمة العملاء',
    enter_verification_code_sent_to_email:
        'يرجى إدخال الرمز المكون من 4 أرقام الذي تم إرساله إلى بريدك الإلكتروني {0}',
    otp_verify: 'تحقق',
    verification_code: 'رمز التحقق',
    resend_otp_failure:
        "حدث خطأ ما، إذا استمرت المشكلة، يرجى الاتصال بدعم العملاء على الرقم ٢٨٩٢٨ ٩٢٠٠ أو عبر البريد الإلكتروني على pay.ops{'@'}foodics.com",
    nearpay_registration: {
        review_details: 'مراجعة البيانات',
        thanks_for_interest: 'نشكرك على اهتمامك باستخدام ميزة تاب تو باي',
        account_created_success: 'لقد تم إنشاء حسابك بنجاح.',
        request_approval_soon:
            'سيتم الموافقة على طلبك قريبًا، وبعدها ستتمكن من تسجيل الدخول واستخدام الخدمة',
    },
    refund_transaction: {
        refund_transaction: 'استرداد قيمة المعاملة',
        refund: 'استرداد المبلغ',
        refund_an_amount_of: 'استرداد مبلغ بقيمة ',
        refund_requested_successfully: 'تم تقديم طلب استرداد المبلغ بنجاح',
        refund_has_been_requested: 'تم تقديم هذا الطلب من قبل',
        transaction_refunded: 'لقد تم بالفعل رد المبلغ المتعلق بهذه المعاملة',
        transaction_older_than_30_days:
            'مضى على هذه المعاملة أكثر من 30 يومًا. يرجى التواصل مع البنك مباشرة لإصدار طلب استرداد.',
        refund_not_allowed:
            'استرداد المبلغ غير مسموح به، يرجى التواصل مع مدير حسابك.',
        transaction_not_found:
            'لم يتم العثور على المعاملة، يرجى التواصل مع مدير حسابك.',
        refund_error: 'حصل خطأ ما، يرجى التواصل مع مدير حسابك.',
        transaction_pending_capture:
            'لم يتم استحقاق مبلغ المعاملة بعد. يرجى التحقق مرة أخرى في وقت لاحق.',
    },
    maximum_date_range: 'لا يمكن تحديد مدة تزيد عن {maxDays} يومًا',
    required_duration: 'الرجاء تحديد المدة',
    terminal_Reconciliation: 'تسوية الأجهزة',
    recon_date: 'تاريخ التسوية',
    total_count: 'العدد الإجمالي',
    mada_total_amount: 'المبلغ الإجمالي لبطاقة مدى',
    visa_total_amount: 'المبلغ الإجمالي لبطاقة فيزا',
    mastercard_total_amount: 'المبلغ الإجمالي لبطاقة ماستركارد',
    terminal_recon_details: 'تفاصيل تسوية الجهاز ',
    terminal_recon_amount: 'مبالغ تسوية الجهاز',
    card_scheme_name: 'نظام بطاقه الدفع',
    host_total_db_count: 'العدد الإجمالي للمعاملات المدينة عبر المضيف',
    host_total_cr_count: 'العدد الإجمالي للمعاملات الائتمانية  عبر المضيف',
    host_total_db_amount: 'المبلغ الإجمالي للمعاملات المدينة  عبر المضيف',
    host_total_cr_amount: 'المبلغ الإجمالي للمعاملات الائتمانية  عبر المضيف',
    pos_total_db_count: 'العدد الإجمالي للمعاملات المدينة عبر نقطة البيع',
    pos_total_cr_count: 'العدد الإجمالي للمعاملات الائتمانية عبر نقطة البيع',
    pos_total_db_amount: 'المبلغ الإجمالي للمعاملات المدينة عبر نقطة البيع',
    pos_total_cr_amount: 'المبلغ الإجمالي للمعاملات الائتمانية عبر نقطة البيع',
    learn_more: 'لمعرفة المزيد',
    refund_amount: 'الرجاء تحديد المبلغ',
    min_refund_amount: 'ينبغي للمبلغ أن يكون فوق 0 ر.س',
    max_refund_amount: 'لا يمكن للمبلغ أن يتخطى قيمة {maxAmount} ر.س',
    unreconciled_terminals: {
        unreconciled_terminals: 'أجهزة دفع بانتظار التسوية',
        reconcile_now: 'بادر إلى التسوية الآن!',
        terminals_unreconciled_for_24_hours_reconcile_to_settle:
            'لديك أجهزة دفع لم تتم تسويتها منذ 24 ساعة أو أكثر. يرجى مراجعتها وإتمام التسوية في أسرع وقت لضمان الدقة في معاملاتك.',
        do_not_remind_me_again: 'لا تذكرني مرة أخرى',
        view_x_unreconciled_terminals:
            'عرض ({unreconciled_terminals_count}) أجهزة لم تتم تسويتها',
        contact_your_branch_to_reconcile: 'تواصل مع فرعك لتسوية اجهزة الدفع',
        terminals_unreconciled_for_24_hours_reconcile:
            'لديك أجهزة دفع لم تتم تسويتها منذ 24 ساعة أو أكثر. يرجى إتمام التسوية.',
        unreconciled: 'لم تتم التسويه',
    },
    cp_transaction_status_tooltip: {
        pending_reconciliation:
            'المعاملات التي تنتظر التسوية قبل البدأ بمرحلة المعالجة. يتضمن هذا عادةً التسوية اليدوية للأجهزة على مستوى الفرع. بمجرد اكتمال التسوية، ستنتقل المعاملات إلى مرحلة المعالجة.',
        processing:
            'المعاملات تتم معالجتها حالياً. يتضمن ذلك جميع الخطوات الأولية من لحظة إجراء المعاملة، حتى النقطة التي تصبح فيها المعاملة جاهزة لبدء الدفع (التحويل). يتضمن ذلك عمليات التحقق من المشكلات المحتملة والتأكد من جميع التفاصيل الضرورية. مدة التسوية غير ثابتة ولكنها تحرز تقدماً خلال أيام العمل.',
        payment_initiated:
            'تم تقديم طلب تحويل الأموال إلى حسابك البنكي. المعاملة في مراحلها النهائية قبل إيداع الأموال في حسابك الخاص. ويعتمد توقيت الإكمال على المعالجة في البنك، وبشكل عام تتم المدفوعات بصورة فورية.',
        settled: 'اكتملت التسوية بالكامل، وتم إيداع الأموال في حسابك.',
    },
    export_transactions: {
        primary_action: 'تحميل العمليات',
        secondary_action: 'تحميل العمليات (يشمل المرفوضة)',
    },
    merchant_reference: 'الرقم المرجعي لحساب فودكس Pay',
    generate_account_statement: 'طلب كشف حساب',
    grouped_by_interval: 'مُجَمَّع حسب الفترات الزمنية',
    looking_for_account_statement: 'هل تبحث عن كشف حساب؟',
    account_statement_for_payment_provides:
        'يوفر كشف الحساب للمدفوعات ملخصًا موجزًا ​​لمبالغ الدفعات، ويتم تنظيمه على فترات يومية أو شهرية، مما يسهل تتبع الدفعات المالية بمرور الوقت',
    view_x_ineligible_CRs: 'عرض ({x}) سجلات تجارية غير مؤهلة',
    already_registered_cr_numbers: 'أرقام السجل التجاري المسجلة بالفعل',
    expired_cr_numbers: 'أرقام السجل التجاري منتهية الصلاحية',
    unauthorized_to_register: 'لا يوجد صلاحية للتسجيل',
    registered_by: 'مسجل بواسطة',
    expiry_date: 'تاريخ الانتهاء',
    registered: 'مسجل بالفعل',
    enter_details_manually: 'أدخل التفاصيل يدويا',
    are_you_a_freelancer: 'لديك وثيقة عمل حر؟',
    return_to_select_eligible_cr: 'الرجوع الى اختيار سجل تجاري مِؤهل',
    no_eligible_c_rs_available_for_selection:
        'لا توجد سجلات تجارية مؤهلة متاحة للاختيار',
    freelance_license_details: 'تفاصيل وثيقة العمل الحر',
    other_commercial_registrations: 'السجلات التجارية الأخرى',
    view_in_foodics_console: 'مشاهدة الطلب في لوحة تحكم فودكس',
    maintenance_request: 'طلب صيانة',
    replacement_request: 'طلب تبديل',
    paper_roll_request: 'طلب ورق',
    confirm_phone_number_message:
        'يرجى تأكيد رقم الهاتف للمتابعة في الإجراءات.',
    request_received: 'تم استلام الطلب',
    request_failed: 'فشل الطلب',
    submit_request: 'تقديم الطلب',
    error: 'خطأ!',
    request_received_message: 'لقد استلمنا طلبك!',
    representatives_contact_message:
        'سيقوم ممثلو خدمة العملاء بالتواصل معك خلال يوم عمل أو يومين.',
    request_failed_message:
        'فشل تقديم الطلب، يرجى المحاولة لاحقاً. و في حال استمرار المشكلة، يرجى التواصل مع',
    ticket_already_open:
        'يوجد تذكرة دعم مفتوحة بالفعل لهذا الجهاز. إذا لم يصلك رد خلال آخر يوميْ عمل، يرجى التواصل معنا على',
    switch_account: 'تبديل الحساب',
    my_account: {
        title: 'تفاصيل الحساب',
        label: 'حسابي',
        business_details: {
            title: 'تفاصيل العمل',
            national_id: 'رقم هوية المالك',
            business_name: 'اسم العمل',
            merchant_reference: 'الرقم المرجعي لحساب فودكس Pay',
            business_reference: 'رقم حساب فودكس (F5)',
            owner_name: 'الاسم الكامل للمالك',
            freelance_license: 'رقم وثيقة العمل الحر',
            commercial_registration_license: 'رقم السجل التجاري',
            unn_number: 'الرقم الموحد',
            vat_registration_number: 'الرقم الضريبي',
            change_business_name_info:
                'تغيير اسم النشاط التجاري سيؤدي إلى تحديث الاسم الذي يظهر في إشعارات الرسائل النصية للعملاء وكشوف حساباتهم البنكية عند الشراء منك.',
            business_name_min_char:
                'الحد الأدنى لعدد الأحرف هو 5 أحرف لاسم العمل',
            required_business_name: 'اسم العمل مطلوب',
        },
        national_address: {
            title: 'العنوان الوطني',
            street_name: 'اسم الشارع',
            street_name_localized: 'اسم الشارع (بالعربية)',
            building_number: 'رقم المبني',
            district: 'الحي',
            district_localized: 'الحي (بالعربية)',
            city: 'المدينة',
            city_localized: 'المدينة (بالعربية)',
            postal_code: 'الرمز البريدي',
            update_national_address: 'تعديل العنوان الوطني',
            national_address_updated_success: 'تم تعديل العنوان الوطني بنجاح.',
            required_street_name: 'اسم الشارع مطلوب',
            required_building_number: 'رقم المبني مطلوب',
            required_district: 'الحي مطلوب',
            required_city: 'المدينة مطلوبة',
            required_postal_code: 'الرمز البريدي مطلوب',
            invalid_postal_code: 'الرمز البريدي يجب ان يحتوي على ارقام فقط',
            invalid_building_number: 'رقم المبنى يجب ان يحتوي على ارقام فقط',
        },
        profile: {
            title: 'حسابي',
            name: 'الاسم',
            email: 'البريد الإلكتروني',
            mobile_number: 'رقم الجوال',
        },
    },
    reviewing_information:
        'نقوم حاليًا بمراجعة المعلومات، وهو ما يستغرق يوم عمل واحد في العادة. سنقوم بإعلامك بمجرد انتهائنا من المراجعة',
    bank_accounts: {
        title: 'الحسابات البنكية',
        approved: 'مقبول',
        pending_review: 'قيد المراجعة',
        rejected: 'مرفوض',
        add_bank_account: 'أضف حساب البنك',
        linked_branches: 'الفروع المرتبطة برقم الآيبان',
        validate_iban: 'جاري التحقق من الحساب',
        update_bank_account: 'تعديل حساب البنك',
    },
    pending_reconciliation: 'في انتظار التسوية',
    processing: 'قيد المعالجة',
    copy_value: 'نسخ القيمة',
    page_title: {
        my_account: 'تفاصيل الحساب',
        dashboard: 'لوحة البيانات',
        payouts_list: 'الدفعات',
        payout_details: 'تفاصيل الدفعة',
        transactions_list: 'العمليات',
        transaction_details: 'تفاصيل العملية',
        vat_statements: 'البيانات الضريبية',
        vat_invoices: 'الفواتير الضريبية',
        account_statement: 'كشف الحساب',
        subscriptions_list: 'الاشتراكات',
        rms_subscription_details: 'تفاصيل اشتراك نظام إدارة المطاعم',
        loans_list: 'القروض',
        loan_details: 'تفاصيل القرض',
        supply_loan_details: 'تفاصيل قرض المورد',
        deduction_details: 'تفاصيل الاستقطاع',
        bank_accounts_list: 'الحسابات البنكية',
        branches_list: 'الفروع',
        branch_details: 'تفاصيل الفرع',
        terminals_list: 'الأجهزة',
        terminal_details: 'تفاصيل الجهاز',
        users_list: 'المستخدمون',
        terminal_recon_list: 'تسوية الأجهزة',
        terminal_recon_details: 'تفاصيل تسوية الجهاز',
        login: 'تسجيل الدخول',
        otp: 'رمز التحقق',
        onboarding: 'Foodics Pay تسجيل',
        nearpay_registration: 'iPhone Tap to Pay تسجيل',
        forget_password: 'نسيت كلمة المرور؟',
        logout: 'تسجيل الخروج',
        verify_nafath: 'التحقق من الهوية',
        impersonation: 'عرض كـ',
        reset_password: 'إعادة تعيين كلمة المرور',
        bank_reconciliation_report: 'تقرير التسوية البنكية',
    },
    change_branch_name: {
        different_branch_name_in_RMS:
            'تم العثور على اسم مختلف لهذا الفرع في لوحة تحكم فودكس',
        click_to_review: 'انقر هنا للمراجعة.',
        hide_this_alert: 'إخفاء هذا التنبيه',
        name_in_console: 'الاسم كما في لوحة التحكم: ',
        use_as_pay_branch_name: 'إستخدام كإسم  للفرع في Pay',
        branch_name_updated: 'تم تحديث اسم الفرع.',
    },
    bank_reconciliation: {
        title: 'تقرير التسوية البنكية',
        export: 'التقرير',
        daily_breakdown: 'التفصيل اليومي',
        day: 'يوم',
        transactions: 'العمليات',
        fees_vat: 'الرسوم + الضريبة',
        net_amount: 'المبلغ الصافي',
        adjustments: 'التعديلات',
        payouts_initiated: 'دفعات قيد التحويل',
        payouts_settled: 'دفعات في الحساب',
        outstanding: 'المبالغ المستحقة',
        total: 'اجمالي',
    },
    active_pending: 'مفعل او في إنتظار التفعيل',
    in_review: 'قيد المراجعة',
    vat_registration_number: 'الرقم الضريبي',
    confirm_vat_registration_number:
        'يرجى تأكيد رقم الضريبة الخاص بك للمتابعة.',
};
export default merge(
    translations,
    customerInsights,
    unauthorized,
    notFound,
    phoneVerfication
);
