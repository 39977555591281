import { getEnumObject } from '../utilities.js';

export const terminalTypes = {
    NEARPAY: 1,
    INTERSOFT: 2,
    PARTNER: 3,
    OTHER: 4,
};

export default getEnumObject(terminalTypes);
