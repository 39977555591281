export default {
    page_title: {
        mobile_registration: 'Mobile Registration',
    },
    mobile_registration: {
        subtitle:
            'Please enter your phone number to receive a One-Time Password (OTP) for login.',
        send_otp: 'Send OTP Code',
        fields: {
            mobile: 'Please enter your phone number',
        },
        back: 'Back To Mobile Registration',
        enter_otp: 'We’ve sent an OTP to your registered phone number {0}.',
        lost_access: 'I lost access to this number.',
        reset_alert: {
            title: 'Reset Mobile Number',
            message:
                'By proceeding, an OTP will be sent to your registered email address and your mobile number will be reset. Are you sure you want to proceed?',
            confirm: 'Proceed',
            cancel: 'Cancel',
        },
    },
};
