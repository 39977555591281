import { getEnumObject } from '../utilities.js';

export const accountTypes = {
    POOL_ACCOUNT: 1,
    COMMISSION_ACCOUNT: 2,
    SUBSCRIPTION_ACCOUNT: 3,
    LOAN_COLLECTION_ACCOUNT: 4,
    RESERVOIR_ACCOUNT: 5,
    FLEX_ACCOUNT: 6,
    SUPPLY: 7,
};

export default getEnumObject(accountTypes);
