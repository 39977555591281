<script setup>
import { useHttp } from '@/common/Composables/useHttp.js';
import { useIsArabic } from '@/common/Composables/useIsArabic';
import { useUserStore } from '@/customer-portal/js/Composables/useUserStore.js';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import MerchantAccountCard from './MerchantAccountCard.vue';

const emit = defineEmits(['account-switched']);
const isArabic = useIsArabic();

const { http } = useHttp();
const { user, setUserInfo, isImpersonating } = useUserStore();
const router = useRouter();

const appSwitcherContainer = ref(null);
const selectedAccount = computed(() => {
    return user.value?.merchant;
});

const relatedAccounts = computed(() => {
    const accounts = user.value?.related_merchant_accounts;
    if (!accounts) {
        return [];
    }

    return Object.entries(accounts).map(([key, value]) => {
        value['merchant_user_id'] = key;
        return value;
    });
});

const allAccounts = computed(() => {
    const accounts = [...relatedAccounts.value];

    if (selectedAccount.value) {
        accounts.unshift(selectedAccount.value);
    }

    return accounts;
});

const isSwitchAccountPopupOpened = ref(false);

const closePopupIfClickedOutside = (e) => {
    const target = e.target;
    if (!target || appSwitcherContainer.value?.contains(target)) {
        return;
    }

    isSwitchAccountPopupOpened.value = false;
};

const toggleSwitchAccountPopup = () => {
    if (!relatedAccounts.value.length) {
        return;
    }

    isSwitchAccountPopupOpened.value = !isSwitchAccountPopupOpened.value;
};
const isSwitchingToAccount = ref('');
const switchAccount = async (account) => {
    try {
        // disable switching to account if in impersonation mode or is already switching to another account
        if (isSwitchingToAccount.value || isImpersonating.value) {
            return;
        }

        // trying to switch to the currently logged in account
        if (account.id === selectedAccount.value.id) {
            return;
        }

        // defensive code
        const merchantUserId = account.merchant_user_id;
        if (!merchantUserId) {
            return;
        }

        isSwitchingToAccount.value = account.id;
        const response = await http().post(
            `/switch_merchant_user/${merchantUserId}`
        );

        await setUserInfo(response.data);
        isSwitchingToAccount.value = '';
        isSwitchAccountPopupOpened.value = false;

        router.push({
            name: 'dashboard',
        });

        emit('account-switched');
    } catch {
        isSwitchingToAccount.value = '';
    }
};
</script>

<template>
    <div v-if="selectedAccount" ref="appSwitcherContainer" class="relative">
        <div
            class="flex gap-x-3 hover:bg-gray-200 cursor-pointer rounded-lg transition py-2 pe-6 ps-3"
            :class="{
                'bg-gray-200 rounded-b-none lg:rounded-b-lg':
                    isSwitchAccountPopupOpened,
                'pointer-events-none': !relatedAccounts.length,
            }"
            @click="toggleSwitchAccountPopup">
            <merchant-account-card :account="selectedAccount" />

            <div
                v-if="relatedAccounts.length"
                class="flex items-center justify-center ms-auto transition-transform lg:rotate-0"
                :class="{
                    'rotate-90': isSwitchAccountPopupOpened && !isArabic,
                    '-rotate-90': isSwitchAccountPopupOpened && isArabic,
                }">
                <svg-icon
                    class="size-6"
                    :icon="isArabic ? 'chevron_left' : 'chevron_right'" />
            </div>
        </div>

        <Transition name="slide-down">
            <div
                v-if="isSwitchAccountPopupOpened"
                v-click-outside="closePopupIfClickedOutside"
                class="lg:absolute lg:top-0 lg:start-full bg-gray-200 lg:bg-white lg:ms-2 lg:shadow-lg lg:w-72 rounded-b-lg rounded-t-none lg:rounded-t-lg">
                <div class="hidden lg:block">
                    <h1 class="text-footnote font-bold ms-4 mb-3 mt-2">
                        {{ $t('switch_account') }}
                    </h1>
                    <hr />
                </div>

                <div
                    class="flex flex-col gap-y-2 p-2 relative max-h-80 overflow-y-scroll">
                    <div
                        v-for="account in allAccounts"
                        :key="account.id"
                        class="hover:bg-gray-100 rounded-lg transition p-2 flex items-center"
                        :class="[
                            isSwitchingToAccount || isImpersonating
                                ? 'cursor-not-allowed opacity-80'
                                : 'cursor-pointer',
                            {
                                'bg-gray-300 lg:bg-gray-100':
                                    account.id === selectedAccount.id,
                            },
                        ]"
                        @click="switchAccount(account)">
                        <merchant-account-card :account show-status />

                        <svg-icon
                            v-if="isSwitchingToAccount === account.id"
                            class="size-6 stroke-primary ms-auto"
                            icon="loader" />

                        <svg-icon
                            v-if="selectedAccount.id === account.id"
                            class="size-6 text-positive-500 ms-auto lg:!hidden"
                            icon="check" />
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>
