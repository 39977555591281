import App from './App.vue';
import en from './I18n/en.js';
import ar from './I18n/ar.js';
import routes from './Routes/index.js';
import BaseCP from './Mixins/baseCP.js';
import mainStore from './Store/mainStore.js';
import mixpanel from 'mixpanel-browser';
import { onboardRequestStatuses } from '@/common/Enums/onboardRequestStatuses.js';
import { createApp, h, ref } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { createI18n } from 'vue-i18n';
import globalComponents from './globalComponents.js';
import {
    clearAuth,
    useUserStore,
} from '@/customer-portal/js/Composables/useUserStore.js';
import { useMixpanel } from '@/common/Composables/useMixpanel.js';
import clickOutside from '@/common/Components/clickOutside.js';
import loadingButton from '@/common/Components/loadingButton.js';

import * as Sentry from '@sentry/vue';

export const router = createRouter({
    routes: routes,
    history: createWebHistory('/'),
    scrollBehavior(to, from) {
        if (to.path === from.path) {
            return { left: 0 };
        }
        return { left: 0, top: 0 };
    },
});

const i18n = createI18n({
    locale: 'en',
    legacy: true,
    // this will be removed in vue-i18n v10
    allowComposition: true,
    messages: {
        en,
        ar,
    },
    silentTranslationWarn: true,
    silentFallbackWarn: true,
});

const app = createApp({
    setup: () => {
        const store = ref(mainStore);

        app.provide('mainStore', store);

        return {
            mainStore: store,
        };
    },
    router,
    render() {
        return h(App, {
            props: {},
        });
    },
});

import './bootstrap';

Sentry.init({
    app,
    dsn: import.meta.env.MIX_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 5.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [/^\/cp_internal/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    trackComponents: true,
    environment: import.meta.env.APP_ENV,
    bundleSizeOptimizations: {
        excludeDebugStatements: !import.meta.env.DEV,
    },
});

app.use(router);
app.use(i18n);
app.mixin(BaseCP);

// Defining Global Component
Object.entries(globalComponents).forEach(([name, component]) => {
    app.component(name, component);
});

app.directive('click-outside', clickOutside);
app.directive('loading', loadingButton);

mixpanel.init(import.meta.env.MIX_PANEL_TOKEN, {
    debug: import.meta.env.DEV,
});

router.beforeEach(async (to, from, next) => {
    const {
        isAuthenticated,
        user,
        setUserInfo,
        hasFeatureEnabled,
        isMobileVerified,
        isImpersonating,
    } = useUserStore();

    setPageTitle(to.meta.pageTitle);

    const isRedirectedToImpersonation = to.query.token;
    if (isRedirectedToImpersonation) {
        next();
        return true;
    }

    if (isAuthenticated.value && !user.value.id) {
        try {
            await setUserInfo();
        } catch (error) {
            next('login');
            return;
        }
    }

    const isRedirectedToLogin = to.name === 'login';
    const requiresAuth = to.meta.requireAuth || from.meta.requireAuth;

    if (requiresAuth && !isAuthenticated.value && !isRedirectedToLogin) {
        clearAuth();
        next('login');
    } else if (
        isAuthenticated.value &&
        !isMobileVerified.value &&
        !isImpersonating.value
    ) {
        const allowedRoutes = [
            'logout',
            'mobile-registration',
            'otpVerification',
        ];
        if (allowedRoutes.includes(to.name)) {
            return next();
        }
        next({ name: 'mobile-registration' });
    } else if (shouldRedirectToDashboard(to, isAuthenticated, user)) {
        next('dashboard');
    } else if (!hasFeatureEnabled(to.meta.featureFlag)) {
        // we can show a page with a button instead to request access to this feature.
        next('unauthorized');
    } else {
        next();
    }
});

router.afterEach((to, from) => {
    mainStore.setFromTo(to, from);
    const isNewPageVisit = to.path !== from.path;
    if (isNewPageVisit) {
        const { mixpanelTrackEvent } = useMixpanel();
        const eventName = to.meta.mixpanelVisitPageEventName ?? 'CP Visit Page';
        mixpanelTrackEvent(eventName);
    }
});

function shouldRedirectToDashboard(to, isAuthenticated, user) {
    if (!isAuthenticated.value) {
        return false;
    }

    const userInfo = user.value ?? {};
    const requestKeyIsMissing = !to.query?.key;
    const userIsNotOwner = to.meta.isOwner && !userInfo.is_owner;

    const isApprovedUser = userInfo.onboard_request
        ? userInfo.onboard_request.status === onboardRequestStatuses.APPROVED
        : true;
    const userIsNotApproved = to.meta.requireApproval && !isApprovedUser;

    const isNotrequiredAuth = [
        'login',
        'forget-password',
        'reset-password',
        'onboard',
        'nearpay-registration',
        'otpVerification',
        'mobile-registration',
    ].includes(to.name);

    return (
        userIsNotOwner ||
        userIsNotApproved ||
        isNotrequiredAuth ||
        (requestKeyIsMissing && ['onboard'].includes(to.name))
    );
}

function setPageTitle(title) {
    const {
        global: { t },
    } = i18n;

    const pageTitle = title ? t(`page_title.${title}`) : t('misc.foodics_pay');
    document.title = pageTitle;
}

app.mount('#app');
