import { getEnumObject } from '../utilities.js';

export const adjustmentFrequencies = {
    '30_Days': 1,
    '7_Days': 3,
    '14_Days': 4,
    '60_Days': 5,
};

export default getEnumObject(adjustmentFrequencies);
