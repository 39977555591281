<script setup>
import { camelCase } from 'lodash';
import SidebarItemLink from './SidebarItemLink.vue';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    activeItem: {
        type: String,
        default: null,
    },
});
const emits = defineEmits(['onClick']);
const hasActiveChild = (menuItem) => {
    return menuItem.children?.some(
        (child) => child.activeSidebarItemId === props.activeItem
    );
};
const isEntryOpen = (menuItem) => {
    return menuItem.open || hasActiveChild(menuItem);
};
const onMenuItemClicked = (item) => emits('onClick', item);
</script>

<template>
    <li
        v-if="!item.children"
        :key="item.name"
        :class="{
            disabled: item.disabled,
        }">
        <sidebar-item-link
            :item
            :is-active="item.activeSidebarItemId === activeItem" />
    </li>
    <li
        v-else
        :class="{
            'active-tree': hasActiveChild(item),
            disabled: item.disabled,
        }">
        <a
            href="#"
            class="flex items-center hover:font-bold will-change-[font-weight]"
            @click.stop.prevent="onMenuItemClicked(item)">
            <div class="flex w-full px-3 h-16 items-center justify-between">
                <div class="flex flex-1 items-center relative">
                    <svg-icon :icon="item.icon" class="!block" />
                    <span class="root-tree-label">
                        {{ $t(`${item.name}_menu`) }}
                    </span>

                    <span v-if="item.meta?.status" class="ms-2 absolute end-0">
                        <fdx-badge :color="item.meta.status.color">
                            {{ $t(item.meta.status.title) }}
                        </fdx-badge>
                    </span>
                </div>
                <svg-icon
                    :class="[
                        'arrow-container transition-transform',
                        { ' rotate-180': isEntryOpen(item) },
                    ]"
                    icon="keyboard_arrow_down" />
            </div>
        </a>
        <Transition appear name="slide-down">
            <ul
                v-show="isEntryOpen(item)"
                class="sub-menu"
                :data-testid="`${camelCase(item.activeSidebarItemId)}List`">
                <li
                    v-for="child in item.children"
                    :id="child.activeSidebarItemId"
                    :key="child.name"
                    :data-testid="camelCase(child.activeSidebarItemId)">
                    <sidebar-item-link
                        :is-active="child.activeSidebarItemId === activeItem"
                        :item="child"
                        is-child />
                </li>
            </ul>
        </Transition>
    </li>
</template>

<style>
.arrow-container svg {
    @apply !me-0;
}
</style>
