export default {
    customer_insights_menu: 'تحليلات العملاء',
    customer_insights_dashboard_menu: 'لوحة البيانات',
    customer_segments_menu: 'شرائح العملاء',
    page_title: {
        customer_insights: 'تحليلات العملاء',
        customer_segments: 'شرائح العملاء',
        customer_segments_create: 'انشاء شريحة جديدة',
        customer_segments_edit: 'تعديل الشريحة',
    },
    customer_insights: {
        help: 'تعرض هذه اللوحة بيانات العمليات التي تتم عبر شاشات العملاء فقط. نود أن نلفت انتباهكم إلى أنه قد يظهر العميل نفسه كعدة سجلات إذا استخدم بطاقات أو طرق دفع مختلفة مثل Apple Pay، وذلك بسبب المعرفات الفريدة لكل بطاقة. نحن نعمل حالياً على تطوير حلول لدمج هذه السجلات بهدف منحكم رؤية أشمل لعملائكم، كما نخطط لتوسيع نطاق الخدمة لتشمل أجهزة الدفع الأخرى مستقبلاً.',
        info: 'احصل على رؤى أعمق حول معاملات شاشة العميل. قم بمواكبة نمو العملاء وقياس مستوى الولاء وتحسين استراتيجيات التسويق.',
        timeline: {
            week: 'آخر 7 أيام',
            month: 'آخر 30 يومًا',
            quarter: 'آخر 3 أشهر',
            previous: {
                week: 'الأيام السبعة السابقة',
                month: 'الأيام الثلاثون السابقة',
                quarter: 'الشهور الثلاثة السابقة',
            },
        },
        something_went_wrong: 'حدث خطأ ما!',
        unexpected_error: 'عفواً! يبدو أنه حدث خطأ غير متوقع.',
        not_able_to_fetch: 'نحن غير قادرين على جلب المعلومات.',
        footfall: {
            title: 'العدد اليومي للزوار',
            info: 'يصور هذا المخطط البياني التوجهات اليومية في نشاط العملاء، ويستعرض أنماط سلوك العملاء الجديدة والمتكررة. استخدم هذا المؤشر لتحديد تأثير أيام معينة أو عروض ترويجية محددة.',
        },
        trends: {
            increase: 'الزيادة مقابل {timeline}',
            decrease: 'النقصان مقابل {timeline}',
            steady: 'ثابت مقابل {timeline}',
        },
        stats: {
            overview: {
                title: 'نظرة عامة',
                count: 'إجمالي عدد المعاملات',
                value: 'إجمالي قيمة المعاملة',
                customers: 'إجمالي العملاء',
            },
            breakdown: {
                title: 'بيان تفصيلي',
                segment: 'الشريحة',
                customers: 'العملاء',
                transactions_count: 'إجمالي عدد المعاملات',
                transactions_value: 'إجمالي قيمة المعاملة',
                transactions_avg_value: 'متوسط ​​قيمة المعاملة',
                uncategorized_info: 'تنتمي هذه البيانات إلى شريحة تم حذفها.',
            },
        },
    },

    customer_segments: {
        create: 'إنشاء شريحة',
        level: 'المرتبة',
        timeframe: 'الإطار الزمني',
        spending: 'الإنفاق',
        branches: 'الفروع',
        name: 'الاسم',
        visits_frequency: 'مُعدل الزيارات',
        week: 'اسبوع | اسبوعين | اسابيع',
        month: 'شهر | شهرين | اشهر',
        days: 'يوم | يومين | ايام',
        different_branches: 'فرع مختلف | فرعين مختلفين | افرع مختلفة',
        visits: 'زيارة | زيارتين | زيارات',
        per: 'كل',
        level_tooltip:
            'يحدد هذا أي شريحة سيتم وضع العميل فيها إذا استوفى معايير عدة شرائح، حيث تأخذ الشريحة ذات المرتبة الأعلى الأولوية.',
        cancel: 'إلغاء',
        save: 'حفظ',
        unauthorized: {
            info: 'لقد وصلت إلى عدد الشرائح القصوى.',
        },
        success: {
            title: 'تم ترتيب الشرائح بنجاح',
            message:
                'تم ترتيب الشرائح الخاصة بك بنجاح. سيتم تطبيق التغييرات خلال الـ 24 ساعة القادمة.',
        },
        error: {
            title: 'حدث خطأ ما',
            message: 'يرجى إعادة تحميل الصفحة والمحاولة مرة أخرى',
        },
    },
    segment_form: {
        title_placeholder: 'عملاء',
        visits: 'الزيارات',
        forecast: {
            title: 'التنبؤات (تقدير إحصائي)',
            tooltip:
                'هذا العدد تقديري وقد لا يعكس العدد الفعلي. وبالتالي يمكن استخدامه كمؤشر عام وليس كقيمة نهائية ودقيقة.',
            customers: 'عملاء | عميلان | عميل',
        },
        fields: {
            title: 'الاسم',
            color: 'اللون',
            per: 'كل',
            timeframe: 'الإطار الزمني',
            average_visits: 'متوسط ​​الزيارات',
            average_spending: 'بمتوسط لا يقل عن',
            unique_visited_branches: 'زار على الأقل',
            visits_frequency: 'مُعدل الزيارات',
            spending: 'الإنفاق',
            branches: 'الفروع',
        },
        help: {
            timeframe:
                'اختر الفترة الزمنية التي سيتم خلالها تحليل سلوك العميل في هذه الشريحة (مثلاً، آخر 3 أشهر).',
            average_visits:
                'حدد الحد الأدنى لمتوسط عدد الزيارات التي يجب أن يقوم بها العميل خلال الإطار الزمني المحدد ليكون ضمن هذه الشريحة.',
            average_spending:
                'حدد الحد الأدنى لمتوسط المبلغ الذي يجب أن ينفقه العميل خلال الإطار الزمني المحدد ليكون ضمن هذه الشريحة.',
            unique_visited_branches:
                'حدد الحد الأدنى لعدد الفروع التي يجب أن يزورها العميل خلال الفترة الزمنية ليكون ضمن هذه الشريحة.',
        },
        month: 'شهر',
        week: 'أسبوع',
        timeframe: 'الإطار الزمني',
        different_branches: 'الفروع المختلفة',
        validation: {
            required: 'هذا الحقل مطلوب',
            numeric: 'هذا الحقل يجب أن يكون رقمًا',
            integer: 'هذا الحقل يجب أن يكون عددًا صحيحًا',
            name_max_length: 'يجب أن يكون الاسم بحد أقصى 16 حرفًا',
            min_visits: 'يجب أن يكون متوسط الزيارات على الأقل 1',
            max_visits: 'يجب أن يكون متوسط الزيارات بحد أقصى 500',
            min_spending: 'يجب أن يكون متوسط الإنفاق على الأقل 1',
            max_spending: 'يجب أن يكون متوسط الإنفاق بحد أقصى 10000',
            min_branches:
                'يجب أن تكون الفروع المختلفة التي تمت زيارتها على الأقل 1',
            max_branches:
                'يجب أن يكون عدد الفروع المختلفة التي تمت زيارتها بحد أقصى 100',
            toggles_error: 'يرجى تفعيل خيار واحد على الأقل من الخيارات أدناه',
            forecast_min:
                'الشريحة التي حددتها ضيقة جداً. يُرجى توسيع نطاق المعايير.',
            forecast_max:
                'الشريحة التي حددتها واسعة جداً. يُرجى تضييق نطاق المعايير.',
        },
        create: {
            title: 'إنشاء شريحة {0}',
            success: {
                title: 'تم إنشاء شريحة جديدة',
                message:
                    'تم إنشاء الشريحة بنجاح. سيتم تطبيق التغييرات خلال الـ 24 ساعة القادمة.',
            },
            error: {
                title: 'حدث خطأ ما',
                message: 'يرجى إعادة تحميل الصفحة والمحاولة مرة أخرى',
            },
        },
        edit: {
            title: 'تعديل شريحة {0}',
            success: {
                title: 'تم تعديل شريحة',
                message:
                    'تم تعديل الشريحة بنجاح. سيتم تطبيق التغييرات خلال الـ 24 ساعة القادمة.',
            },
            error: {
                title: 'حدث خطأ ما',
                message: 'يرجى إعادة تحميل الصفحة والمحاولة مرة أخرى',
            },
        },
        delete: {
            title: 'حذف',
            confirm: {
                title: 'هل أنت متأكد أنك تريد حذف هذه الشريحة؟',
                message:
                    ' هذا الإجراء لا يمكن التراجع عنه، وسيتم حذف الشريحة نهائيًا.',
                proceed: 'أفهم، احذف',
            },
            success: {
                title: 'تم حذف الشريحة',
                message:
                    'تم حذف الشريحة بنجاح. سيتم تطبيق التغييرات خلال الـ 24 ساعة القادمة.',
            },
            error: {
                title: 'حدث خطأ ما',
                message: 'يرجى إعادة تحميل الصفحة والمحاولة مرة أخرى',
            },
        },
    },
};
