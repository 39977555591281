import rtl from 'tailwindcss-rtl';
import defaultTheme from 'tailwindcss/defaultTheme';

export default {
    content: [
        './resources/**/*.blade.php',
        './resources/**/*.js',
        './resources/**/*.vue',
    ],
    theme: {
        extend: {
            colors: {
                white: '#fff',
                black: '#000',
                transparent: 'transparent',
                current: 'currentColor',
                primary: {
                    DEFAULT: '#440099',
                    100: '#E1D6FF',
                    200: '#A38EDE',
                    300: '#7457C5',
                    400: '#440099',
                    500: '#230575',
                },
                secondary: {
                    DEFAULT: '#004FB5',
                    100: '#CCE2FF',
                    200: '#78C4DD',
                    300: '#1877F2',
                    400: '#004FB5',
                    500: '#002C66',
                },
                gray: {
                    100: '#F2F2F7',
                    200: '#E5E5EA',
                    300: '#D1D1D6',
                    400: '#C7C7CC',
                    500: '#AEAEB2',
                    600: '#8E8E93',
                    700: '#636366',
                    800: '#48484A',
                    900: '#3A3A3C',
                    1000: '#1C1C1E',
                },
                positive: {
                    400: '#E6FFEC',
                    500: '#248A3D',
                    600: '#1E7734',
                },
                negative: {
                    400: '#FFE5E8',
                    500: '#D70015',
                    600: '#BB0011',
                },
                warning: {
                    400: '#FFF2E5',
                    500: '#FF800A',
                    600: '#DE6E07',
                },
                alert: {
                    400: '#FFFBE5',
                    500: '#FFD60A',
                    600: '#E5BF00',
                },
            },
            minWidth: {
                64: '16rem',
            },
            margin: {
                80: '20rem',
            },
            zIndex: {
                1: '1',
                2: '2',
                3: '3',
                4: '4',
                5: '5',
                6: '6',
                7: '7',
                8: '8',
                9: '9',
                100: '100',
                1000: '1000',
                10000: '10000',
                100000: '100000',
                1000000: '1000000',
            },
            fontSize: {
                'caption-2': ['.625rem', '1rem'], // 10px 16px
                'caption-1': ['.75rem', '1rem'], // 12px 16px
                footnote: ['.875rem', '1.5rem'], // 14px 24px
                callout: ['.9375rem', '1.5rem'], // 15px 24px
                body: ['1rem', '1.5rem'], // 16px 24px
                'title-3': ['1.25rem', '2rem'], // 20px 32px
                'title-2': ['1.375rem', '2rem'], // 22px 32px
                'title-1': ['1.75rem', '2.5rem'], // 28px 40px
                'large-title': ['2.125rem', '3rem'], // 34px 48px
            },
            boxShadow: {
                /* Dropdown */
                xs: '-5px 8px 10px 0px rgba(229, 228, 235, 0.40)',
                /* Line Shadow */
                sm: '0px 1px 0px 0px #D1D1D6',
                /* Button */
                md: '-1px 1px 4px 0px rgba(23, 13, 71, 0.26)',
                /* Modal */ /* Card */
                lg: '2px 0px 3px 0px rgba(38, 38, 38, 0.10), 0px 4px 20px 0px rgba(38, 38, 38, 0.15)',
                none: '0 0 0 0',
            },
        },

        screens: { ...defaultTheme.screens },

        borderColor: (theme) => ({
            DEFAULT: '#d6dbe7',
            ...theme('colors'),
        }),

        fill: (theme) => ({
            current: 'currentColor',
            'gray-100': theme('colors.gray.100'),
            'gray-200': theme('colors.gray.200'),
            'gray-300': theme('colors.gray.300'),
            'gray-400': theme('colors.gray.400'),
            'gray-500': theme('colors.gray.500'),
            'gray-550': theme('colors.gray.550'),
            'gray-600': theme('colors.gray.600'),
            primary: theme('colors.primary'),
            'primary-300': theme('colors.primary.300'),
            secondary: theme('colors.secondary'),
            'secondary-300': theme('colors.secondary.300'),
            red: theme('colors.red'),
            'red-300': theme('colors.red.300'),
            green: theme('colors.green.100'),
            yellow: theme('colors.yellow'),
            white: theme('colors.white'),
        }),

        fontFamily: {
            serif: [
                'IBM Plex Sans',
                'Muli',
                'Merriweather',
                'Georgia',
                'Cambria',
                '"Times New Roman"',
                'Times',
                'serif',
            ],
            arabic: ['IBM Plex Sans Arabic', 'Arial', 'sans-serif'],
        },
        width: (theme) => ({
            auto: 'auto',
            ...theme('spacing'),
            '1/2': '50%',
            '1/3': '33.33333%',
            '2/3': '66.66667%',
            '1/4': '25%',
            '2/4': '50%',
            '3/4': '75%',
            '1/5': '20%',
            '2/5': '40%',
            '3/5': '60%',
            '4/5': '80%',
            '1/6': '16.66667%',
            '2/6': '33.33333%',
            '3/6': '50%',
            '4/6': '66.66667%',
            '5/6': '83.33333%',
            '1/12': '8.33333%',
            '2/12': '16.66667%',
            '3/12': '25%',
            '4/12': '33.33333%',
            '5/12': '41.66667%',
            '6/12': '50%',
            '7/12': '58.33333%',
            '8/12': '66.66667%',
            '9/12': '75%',
            '10/12': '83.33333%',
            '11/12': '91.66667%',
            full: '100%',
            screen: '100vw',
        }),
        stroke: (theme) => ({
            current: 'currentColor',
            primary: theme('colors.primary'),
            gray: '#ccc',
        }),
    },
    plugins: [rtl],
};
